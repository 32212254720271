import React, { useEffect,useRef } from "react";
import "../../Styles/JobCards.css";
import Header from "../../Components/header/Header";
import Sidebar from "../../Components/Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../Images/filterIcon.png";
import shareIcon from "../../Images/shareIcon.png";
import sortIcon from "../../Images/sortIcon.png";
import eyeIcon from "../../Images/eyeIcon.svg";
import Moment from "moment";
import calenderIcon from "../../Images/calenderIcon.png";
import { useState } from "react";
import { toast } from "react-toastify";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Modal from "react-bootstrap/Modal";
import IndentService from "../../services/IndentService";
import ClientsService from "../../services/ClientsService";
import estimateService from "../../services/estimateService";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import authService from "../../services/authService";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Indent = () => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [indentscount, setIndentsCount] = useState(0);
  const [search, setSearch] = useState(false);
  useEffect(() => {
    getclients();
  }, []);
  useEffect(() => {
    getindents(0, 10);
  }, []);

  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  // };
  const handleChangePage = (event, newPage) => {
    const dett = newPage * rowsPerPage;
    setPage(newPage);
    getindents(dett, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getindents(0, event.target.value);
  };
  // const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  // };

  const resetorder = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        searchkeys: '',
       
      };
      const dert = await IndentService.getindents(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setindents(dert.data.value);
        setIndentsCount(dert.data.count);
      }
    } catch (ex) {}
  };

  const [role, setRole] = useState("");
  const [indentno, setIndentNo] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [jobCardNo, setJobCardNo] = useState("");
  const [orderId, setOrderId] = useState("");
  const [estimateId, setEstimateId] = useState("");
  const [jobCardId, setJobCardId] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [papertype, setPaperType] = useState("");
  const [gsm, setGsm] = useState("");
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [fromdate, setFromDate] = useState("");
  const [datetab, setDateTab] = useState("From");
  const [datefilter, setDateFilter] = useState(false);
  const [sname, setSname] = useState("");
  const [filtstatus, setFiltStatus] = useState("");
  const [datafilter, setDataFilter] = useState(false);
  const [fromAmount, setFromAmount] = useState("");
  const [status, setStatus] = useState("");
  const [client, setClient] = useState();
  const multiselectRef = useRef();
  const [todate, setToDate] = useState("");

  const [clients, setClients] = useState([]);
  const [cname, setCname] = useState();
  const [sbtndis, setSBtndis] = useState(false);
  let navigate = useNavigate();

  const create = async () => {
    if (!indentno) {
      await setSBtndis(false);
      toast.error("Select Indent Id", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!date) {
      await setSBtndis(false);
      toast.error("Select Date", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!time) {
      await setSBtndis(false);
      toast.error("Select Time", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!orderNo) {
      await setSBtndis(false);
      toast.error("Select Order Id", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!jobCardNo) {
      await setSBtndis(false);
      toast.error("Select Job Card Id", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // } else if (!status) {
      //     await setSBtndis(false)
      //     toast.error('Select Status', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else if (!sname) {
      await setSBtndis(false);
      toast.error("Select Supplier", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!papertype) {
      await setSBtndis(false);
      toast.error("Select Paper Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!gsm) {
      await setSBtndis(false);
      toast.error("Select GSM", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!quantity) {
      await setSBtndis(false);
      toast.error("Enter Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!size) {
      await setSBtndis(false);
      toast.error("Enter Size", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    try {
      const obj = {
        indentNo: indentno,
        orderId: orderId,
        estimateId: estimateId,
        jobCardId: jobCardId,
        orderNo: orderNo,
        jobCardNo: jobCardNo,
        date: date,
        paperType: papertype,
        gsm: gsm,
        size: size,
        quantity: quantity,
        supplierName: sname,
        status: status,
      };

      const dert = await IndentService.addindents(obj);
      if (dert.data.status === "success") {
        toast.success("Indent Added Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate("/indent");
      } else {
        toast.error(dert.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      if (ex.response.status === 400) console.log(ex.response, "ex.response");
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const [modalShow, setModalShow] = React.useState(false);

  const [indents, setindents] = useState([]);
  const getindents = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        "searchkeys": searchtext,
      };
      const dert = await IndentService.getindents(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setindents(dert.data.value);
        setIndentsCount(dert.data.count);
      }
    } catch (ex) {}
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      serachclick();
     
    }
  };
  //SearchAPI
  const [searchtext, setSearchText] = useState("");
  const serachclick = async () => {
    const trimmedSearchText = searchtext.trim(); 
    if (trimmedSearchText === "") {
      toast.error("Enter Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    }
    else{
    try {
      const obj = {
        skip: 0,
        limit: rowsPerPage,
        searchkeys: trimmedSearchText ,
    };
    const dert = await IndentService.getindents(obj);
    if (dert.data.status === "success") {
      console.log(dert.data.value, "values");
      setindents(dert.data.value);
      setIndentsCount(dert.data.count);
      setSearch(true)
    }
    } catch (ex) {}
  }
  };
  const resetclick = () => {
    setSearchText("");
      setSearch(false)
      resetorder(0,rowsPerPage);
  }

  const searchChange = (value) => {
    setSearchText(value);
    // getestimates(0,10)
  };
  const getclients = async () => {
    try {
      const dert = await ClientsService.getclients();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setClients(dert.data.value);
      }
    } catch (ex) {}
  };
  const selectemps = (value) => {
    setCname(value[0].name);
  };

  //SearchAPI Close

  // download Excel

  const [isDownloading, setIsDownloading] = useState(false);

  // const handleDownloadExcel = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const response = await axios.post(
  //       "/materialIndents/jsontoexcel",
  //       { data: [] },
  //       { responseType: "blob" }
  //     );
  //     const excelBlob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(excelBlob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "Indents.xlsx";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const requestData = {
        // Add your request object properties here
        // For example:
        searchkeys: searchtext,
        "filterBy": {
          status: filtstatus,
          customerName: cname,
          fromAmount: fromAmount,
          toAmount: toAmount,
          fromDate: fromdate,
          toDate: todate,
        }
      };
      const response = await axios.post(
        "/materialIndents/jsontoexcel",
        requestData,
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Indents.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };
  // const filterbydata = async () => {
  //   try {
  //     const obj = {
  //       skip: 0,
  //       limit: 10,

  //       filterBy: {
  //         // "homeDelivery": true,
  //         status: filtstatus,
  //         customerName: cname,
  //         fromAmount: parseFloat(fromAmount),
  //         toAmount: parseFloat(toAmount),
  //         // "fromDate":"2022-07-17",
  //         // "toDate":"2023-02-15"
  //       },
  //     };
  //     const dert = await authService.getindents(obj);
  //     if (dert.data.status === "success") {
  //       setFiltStatus("");
  //       setFromAmount("");
  //       setToAmount("");
  //       setDataFilter(false);
  //       // setSearch(true)
  //       // console.log(dert.data.value, 'values');
  //       setIndentsCount(dert.data.count);
  //       setindents(dert.data.value);
  //       getindents(0,10)
  //     }
  //   } catch (ex) {}
  // };
  // const clickfiltdate = async () => {
  //   try {
  //     const obj = {
  //       skip: 0,
  //       limit: 10,

  //       filterBy: {
  //         fromDate: fromdate,
  //         toDate: todate,
  //       },
  //     };
  //     const dert = await authService.getindents(obj);
  //     if (dert.data.status === "success") {
  //       setFiltStatus("");
  //       setFromDate("");
  //       setToDate("");
  //       setDateFilter(false);
  //       // setSearch(true)
  //       // console.log(dert.data.value, 'values');
  //       setIndentsCount(dert.data.count);
  //       setindents(dert.data.value);
  //     }
  //   } catch (ex) {}
  // };
  // const filtdrop = () => {
  //   setDataFilter(!datafilter);
  // };
  // const datefiltdrop = () => {
  //   setDateFilter(!datefilter);
  // };
  // const onDateChange = (e) => {
  //   console.log(e, "datevalue");
  //   setFromDate(e);
  // };
  // const onToDateChange = (e) => {
  //   setToDate(e);
  // };
  // const activetabclick = (tab) => {
  //   setDateTab(tab);
  // };

  //  Download Excel Close

  // const [role,setRole] = useState('');

  const viewindent = (item) => {
    console.log("item==", item);
    setindents(item);
    // setModalShow(true)
    navigate("/printindent", { state: { item: item } });
  };
  const plateIndent = (item) => {
    console.log("item==", item);
    setindents(item);
    // setModalShow(true)
    navigate("/plateindent", { state: { item: item } });
  };

  const handleStatus = async (e, item) => {
    console.log(item, "e.target.value");
    try {
      const obj = {
        orderId: item.orderId,
        status: e.target.value,
      };
      const der = await estimateService.updatestatus(obj);
      if (der.data.status === "success") {
        getindents(0, 10);
      }
    } catch (ex) {}
  };
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={4} className="containerr px-3 px-lg-4">
            <div className="pt-4 pb-2 ">
              <div>
                <h1 className="fs-3">Indents</h1>
                <nav aria-label="breadcrumb" className="fs-14">
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Indents
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <Row className="d-flex">
              <Col lg={12} sm={12} md={6} xs={12} className="mb-5 datacenter">
                <Paper className="col-lg-12 col-sm-8 rounded-4">
                  <Box sx={{}}>
                    <Row>
                      <Col lg={4} sm={12} className="text-lg-start ">
                        <h6 className="datahead text-muted">Indents</h6>
                      </Col>
                      {/* categories starts */}
                      <Col lg={8} sm={12} className="px-4 pb-4">
                        <Row className="d-flex justify-content-end">
                          <Col lg={5} xs={12}>
                            <Form className="mt-3 d-flex gap-2 searchField">
                              <div className="col-lg-8 d-flex">
                                <input
                                  value={searchtext}
                                  onChange={(e) => searchChange(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  className="form-control nosubmit"
                                  style={{ fontSize: "14px" }}
                                  type="search"
                                  placeholder="Search Vendor Name"
                                />
                              </div>
                              <div className="col-lg-4">
                              {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Search
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}
                                {/* <span className="searchcont" onClick={() => serachclick()} style={{cursor:'pointer'}}><i class="fa fa-search empsearch"></i></span> */}
                              </div>
                            </Form>
                          </Col>
                          {/* sort filter */}
                          {/* <Col lg={2} xs={4} className="px-1">
                            <Button
                              onClick={() => filtdrop()}
                              id="sortfilter"
                              className="sortFilter1 w-100 mt-3"
                              variant="contained"
                            >
                              <img className="menuIcon mx-1" src={filterIcon} />
                              Filter
                            </Button>
                            {datafilter === true ? (
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "white",
                                  right: "40px",
                                  boxShadow: "1px 2px 9px #F4AAB9",
                                }}
                              >
                                <Typography sx={{ p: 2 }} className="col-lg-12">
                                  <h6>Filter</h6>

                                  <div>
                                    <h6 className="mt-2 fs-13">Status</h6>
                                    <Form.Select
                                      value={filtstatus}
                                      onChange={(e) =>
                                        setFiltStatus(e.target.value)
                                      }
                                      aria-label="Default select example"
                                    >
                                      <option>All</option>
                                      <option value="ESTIMATE">ESTIMATE</option>
                                      <option value="ORDER-CONFIRMED">
                                        ORDER-CONFIRMED
                                      </option>
                                      <option value="JOB-Create">
                                        JOB-Create
                                      </option>
                                      <option value="DELIVERED">
                                        DELIVERED
                                      </option>
                                    </Form.Select>
                                    <h6 className="mt-2 fs-13">Customer</h6>
                                    <Multiselect
                                      placeholder="Select Firm Name"
                                      className="border rounded"
                                      options={clients}
                                      selectionLimit={1}
                                      singleSelect={true}
                                      emptyRecordMsg={"Customer Not Found"}
                                      selectedValues={client}
                                      defaltValue={client}
                                      displayValue="name"
                                      ref={multiselectRef}
                                      onSelect={(value) => selectemps(value)}
                                    />
                                    <h6 className="mt-2 fs-14">Amount</h6>
                                    <Form>
                                      <Row>
                                        <Col>
                                          <Form.Label>From</Form.Label>
                                          <Form.Control
                                            value={fromAmount}
                                            onChange={(e) =>
                                              setFromAmount(e.target.value)
                                            }
                                            type="number"
                                            className="filterFields"
                                            placeholder="0.00"
                                          />
                                        </Col>
                                        <Col>
                                          <Form.Label>To</Form.Label>
                                          <Form.Control
                                            value={toAmount}
                                            onChange={(e) =>
                                              setToAmount(e.target.value)
                                            }
                                            type="number"
                                            placeholder="0.00"
                                          />
                                        </Col>
                                      </Row>
                                    </Form>
                                    <Button
                                      onClick={() => filterbydata()}
                                      id="pillStyle"
                                      className="w-100 mt-3"
                                      variant="primary"
                                    >
                                      Filter
                                    </Button>{" "}
                                  </div>
                                </Typography>
                              </div>
                            ) : null}
                           
                          </Col> */}
                          {/* date filter */}
                          {/* <Col lg={2} xs={4} className="px-1">
                            <Button
                              onClick={() => datefiltdrop()}
                              id="sortfilter"
                              className="sortFilter1 w-100 mt-3"
                              variant="contained"
                            >
                              <img
                                className="menuIcon mx-1"
                                src={calenderIcon}
                              />
                              Date
                            </Button>
                            {datefilter ? (
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "white",
                                  right: "40px",
                                  boxShadow: "1px 2px 9px #F4AAB9",
                                }}
                              >
                                <div
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    flex: 1,
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor:
                                        datetab === "From"
                                          ? "#0d6efd"
                                          : "white",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => activetabclick("From")}
                                    className="col-lg-6 text-center"
                                  >
                                    <p
                                      style={{
                                        color:
                                          datetab === "From"
                                            ? "white"
                                            : "black",
                                      }}
                                      id="pillStyle"
                                      href="#dateRange"
                                    >
                                      From
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor:
                                        datetab === "To" ? "#0d6efd" : "white",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => activetabclick("To")}
                                    className="col-lg-6 text-center"
                                  >
                                    <p
                                      style={{
                                        color:
                                          datetab === "To" ? "white" : "black",
                                      }}
                                      id="pillStyle"
                                      eventKey="link-2"
                                    >
                                      To
                                    </p>
                                  </div>
                                </div>
                                <Typography sx={{ p: 2 }} className="col-lg-12">
                                  {datetab === "From" ? (
                                    <Calendar
                                      onChange={onDateChange}
                                      value={fromdate}
                                    />
                                  ) : (
                                    <Calendar
                                      onChange={onToDateChange}
                                      value={todate}
                                    />
                                  )}
                                  <Button
                                    onClick={() => clickfiltdate()}
                                    id="pillStyle"
                                    className="w-100 mt-3"
                                    variant="primary"
                                  >
                                    Filter
                                  </Button>
                                </Typography>
                              </div>
                            ) : null}

                            {/* <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div>
                                                                    <Button id='sortfilter' className='sortFilter2 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1 mb-1" src={calenderIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }}>
                                                                            <h6 className='mb-3 fw-bolder'>By Date</h6>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3 text-muted">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3 text-muted">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3 text-muted">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />&nbsp;
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <hr className='text-muted'></hr>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            onClick={() => setIsHidden(!isHidden)}
                                                                                            label="Date Range"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            {isHidden ? null : <div>
                                                                                <Nav id='navPill' variant="pills" defaultActiveKey="/home">
                                                                                    <Nav.Item className='col-lg-6 text-center'>
                                                                                        <Nav.Link id='pillStyle' href='#dateRange'>From</Nav.Link>
                                                                                    </Nav.Item>
                                                                                    <Nav.Item className='col-lg-6 text-center'>
                                                                                        <Nav.Link id='pillStyle' eventKey="link-2">To</Nav.Link>
                                                                                    </Nav.Item>
                                                                                </Nav>
                                                                                <Col lg={2} sm={2} md={2} className='mt-4'>
                                                                                    <DateRange
                                                                                        editableDateInputs={true}
                                                                                        onChange={item => setState([item.selection])}
                                                                                        moveRangeOnFirstSelection={false}
                                                                                        ranges={state}
                                                                                    /></Col>
                                                                            </div>}
                                                                            <Button className='w-100 mt-3' id='pillStyle' variant="primary">Filter</Button>{' '}
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState> */}
                          {/* </Col>  */}
                          {/* Export Excel */}
                          <Col lg={3} xs={4} className="px-1">
                            <Button
                              onClick={handleDownloadExcel}
                              disabled={isDownloading}
                              id="shareIcon"
                              variant="outlined"
                              className="w-100"
                            >
                              <img
                                className="menuIcon mx-1 mb-1"
                                src={shareIcon}
                              />
                              {isDownloading ? "Downloading" : " Export Excel"}
                            </Button>
                          </Col>
                          {/* Bulk Select */}
                          {/* <Col lg={3} sm={2}>
                                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                                            <option hidden className='text-center'>Bulk Action</option>
                                                            <option value="1">Delete</option>
                                                            <option value="2">Cancel</option>
                                                        </Form.Select>
                                                    </Col> */}
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <Form.Check aria-label="option 1" /></div>
                                                    </th> */}
                          <th>
                            <div className="table-headFilter ps-2">
                              <span>#</span>
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Paper Vendor</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Plate Vendor</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Order ID</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Order Date</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          {/* <th>
                            <div className="table-headFilter">
                              <span>Quantity</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Order Type</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          
                          <th>
                            <div className="table-headFilter">
                              <span>Order Total</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th> */}
                          {/* <th>
                            <div className="table-headFilter">
                              <span>Action</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Status</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th> */}
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {indents && indents.length > 0
                          ? indents.map((k, index) => {
                              return (
                                <tr>
                                  <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                  {/* <Form.Check aria-label="option 1" /> */}
                                  <td>{k.supplierName}</td>
                                  <td>{k.plateBy}</td>
                                  <td>{k.orderId}</td>
                                  <td>{Moment(k.date).format("DD MMM YYYY")}</td>
                                  {/* <td>{k.quantity}</td>
                                  <td>{k.orderType}</td>
                                  <td>{k.sum_total}</td> */}
                                  {/* <td className="accordionsize">
                                    {" "}
                                    <Form.Select
                                      id="accordion-body"
                                      value={k.status}
                                      onChange={(e) => handleStatus(e, k)}
                                      aria-label="Default select example"
                                    >
                                      <option value="MaterialIndent-Created">Material Indent-Created</option>
                                      <option value="Pre-Printing">Pre Printing</option>
                                      <option value="Post-Printing">Post Printing</option>
                                      <option value="MaterialIndent-Cancelled">Material Indent-Cancelled</option>
                                    </Form.Select>
                                  </td> */}
                                  {/* <td>{k.status}</td> */}
                                  <td onClick={() => viewindent(k)}  className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={eyeIcon}
                                    />
                                    Paper Indent
                                  </td>
                                  <td onClick={() => plateIndent(k)}  className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={eyeIcon}
                                    />
                                    Plate Indent
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        <TablePagination
                          component="div"
                          count={indentscount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Material Indent
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className=" rounded-4">
            <CardContent>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="input-group">
                    <select
                      value={indentno}
                      onChange={(e) => setIndentNo(e.target.value)}
                      class="form-select text-muted mb-3 "
                      id="inputcolor"
                      aria-label="Default select example"
                    >
                      <option selected hidden>
                        Indent ID*(auto generate)
                      </option>
                      <option value="17/22-23">17/22-23</option>
                      <option value="19/22-23">19/22-23</option>
                      <option value="20/22-23">20/22-23</option>
                    </select>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-group mb-3">
                          <input
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            type="date"
                            id="inputcolor"
                            className="form-control text-muted required"
                            name="date "
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-group mb-3">
                          <input
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                            type="time"
                            className="form-control text-muted required"
                            id="inputcolor"
                            name="time"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-group">
                    <select
                      value={orderNo}
                      onChange={(e) => setOrderId(e.target.value)}
                      class="form-select text-muted mb-3 "
                      id="inputcolor"
                      aria-label="Default select example"
                    >
                      <option selected hidden>
                        Order ID*
                      </option>
                      <option value="15/22-23-Ganesh Order-10-02-23">
                        15/22-23-Ganesh Order-10-02-23
                      </option>
                      <option value="14/22-23-test my record-09-02-23">
                        14/22-23-test my record-09-02-23
                      </option>
                      <option value="11/22-23-TEST-APP-31-01-23">
                        11/22-23-TEST-APP-31-01-23
                      </option>
                      <option value="12/22-23-test case-31-01-23">
                        12/22-23-test case-31-01-23
                      </option>
                      <option value="13/22-23-tes my one-31-01-23">
                        13/22-23-tes my one-31-01-23
                      </option>
                      <option value="9/22-23-GANESH-TEST-27-01-23">
                        9/22-23-GANESH-TEST-27-01-23
                      </option>
                      <option value="10/22-23-GANESH-TEST-27-01-23">
                        10/22-23-GANESH-TEST-27-01-23
                      </option>
                      <option value="8/22-23-GANESH-19-01-23">
                        8/22-23-GANESH-19-01-23
                      </option>
                      <option value="6/22-23-RC Design Space-05-01-23">
                        6/22-23-RC Design Space-05-01-23
                      </option>
                      <option value="7/22-23-Wedding Cards-05-01-23">
                        7/22-23-Wedding Cards-05-01-23
                      </option>
                      <option value="4/22-23-Sridevi hospital It hd-17-07-22">
                        4/22-23-Sridevi hospital It hd-17-07-22
                      </option>
                      <option value="5/22-23-Thokalas wed cards-30-07-22">
                        5/22-23-Thokalas wed cards-30-07-22
                      </option>
                      <option value="3/22-23-Vasudha pamplets-17-07-22">
                        3/22-23-Vasudha pamplets-17-07-22
                      </option>
                      <option value="1/22-23-Nav Bharath Stickers-01-04-22">
                        1/22-23-Nav Bharath Stickers-01-04-22
                      </option>
                    </select>
                  </div>

                  <div className="input-group">
                    <select
                      value={jobCardNo}
                      onChange={(e) => setJobCardId(e.target.value)}
                      class="form-select text-muted mb-3 "
                      id="inputcolor"
                      aria-label="Default select example"
                    >
                      <option selected hidden>
                        JobCard ID*
                      </option>
                      <option value="15/22-23-Ganesh Order-10-02-23">
                        15/22-23-Ganesh Order-10-02-23
                      </option>
                      <option value="14/22-23-test my record-09-02-23">
                        14/22-23-test my record-09-02-23
                      </option>
                      <option value="11/22-23-TEST-APP-31-01-23">
                        11/22-23-TEST-APP-31-01-23
                      </option>
                      <option value="12/22-23-test case-31-01-23">
                        12/22-23-test case-31-01-23
                      </option>
                      <option value="13/22-23-tes my one-31-01-23">
                        13/22-23-tes my one-31-01-23
                      </option>
                      <option value="9/22-23-GANESH-TEST-27-01-23">
                        9/22-23-GANESH-TEST-27-01-23
                      </option>
                      <option value="10/22-23-GANESH-TEST-27-01-23">
                        10/22-23-GANESH-TEST-27-01-23
                      </option>
                      <option value="8/22-23-GANESH-19-01-23">
                        8/22-23-GANESH-19-01-23
                      </option>
                      <option value="6/22-23-RC Design Space-05-01-23">
                        6/22-23-RC Design Space-05-01-23
                      </option>
                      <option value="7/22-23-Wedding Cards-05-01-23">
                        7/22-23-Wedding Cards-05-01-23
                      </option>
                      <option value="4/22-23-Sridevi hospital It hd-17-07-22">
                        4/22-23-Sridevi hospital It hd-17-07-22
                      </option>
                      <option value="5/22-23-Thokalas wed cards-30-07-22">
                        5/22-23-Thokalas wed cards-30-07-22
                      </option>
                      <option value="3/22-23-Vasudha pamplets-17-07-22">
                        3/22-23-Vasudha pamplets-17-07-22
                      </option>
                      <option value="1/22-23-Nav Bharath Stickers-01-04-22">
                        1/22-23-Nav Bharath Stickers-01-04-22
                      </option>
                    </select>
                  </div>
                  <div className="input-group">
                    <select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      class="form-select text-muted mb-3 "
                      id="inputcolor"
                      aria-label="Default select example"
                    >
                      <option selected hidden>
                        Status*
                      </option>
                      <option value="Created">Created </option>
                      <option value="Approved">Approved</option>
                      <option value="Received">Received</option>
                      <option value="Closed">Closed</option>
                      <option value="Canceled">Canceled</option>
                    </select>
                  </div>
                  <div className="input-group">
                    <select
                      value={sname}
                      onChange={(e) => setSname(e.target.value)}
                      class="form-select text-muted mb-3 "
                      id="inputcolor"
                      aria-label="Default select example"
                    >
                      <option selected hidden>
                        Supplier*
                      </option>
                      <option value="Gayatri">Gayatri</option>
                      <option value="Hari Kisan Paper">Hari Kisan Paper</option>
                      <option value="Jai Shankar Khagad bhandhar">
                        Jai Shankar Khagad bhandhar
                      </option>
                    </select>
                  </div>

                  <div className="input-group">
                    <select
                      value={papertype}
                      onChange={(e) => setPaperType(e.target.value)}
                      class="form-select text-muted mb-3 "
                      id="inputcolor"
                      aria-label="Default select example"
                    >
                      <option selected hidden>
                        Paper Type*
                      </option>
                      <option value="Art card">Art card</option>
                      <option value="Art paper">Art paper</option>
                      <option value="Sticker">Sticker</option>
                      <option value="Cyber excel">Cyber excel</option>
                      <option value="Duplex board">Duplex board</option>
                      <option value="Foam">Foam</option>
                      <option value="JK ivory card">JK ivory card</option>
                      <option value="Maplitho">Maplitho</option>
                      <option value="Metallic card">Metallic card</option>
                      <option value="Duplex White Back">
                        Duplex White Back
                      </option>
                      <option value="3Duplex Grey Back">
                        Duplex Grey Back
                      </option>
                      <option value="Bond Paper">Bond Paper</option>
                      <option value="softfoll">softfoll</option>
                    </select>
                  </div>
                  <div className="input-group">
                    <select
                      value={gsm}
                      onChange={(e) => setGsm(e.target.value)}
                      class="form-select text-muted mb-3 "
                      id="inputcolor"
                      aria-label="Default select example"
                    >
                      <option selected hidden>
                        GSM*
                      </option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="120">120</option>
                      <option value="150">150</option>
                      <option value="180">180</option>
                      <option value="200">200</option>
                      <option value="230">230</option>
                      <option value="250">250</option>
                      <option value="300">300</option>
                      <option value="400">400</option>
                      <option value="350">350</option>
                      <option value="420">420</option>
                      <option value="600">600</option>
                      <option value="500">500</option>
                      <option value="460">460</option>
                    </select>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control text-muted required"
                        name="name"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        id="inputcolor"
                        placeholder="Quantity*"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control text-muted required"
                        name="name"
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                        id="inputcolor"
                        placeholder="Size*"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-5">
                      <Button
                        onClick={() => setModalShow(false)}
                        id="pillStyle"
                        className="w-100"
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-lg-6">
                      <Button
                        onClick={() => create()}
                        id="pillStyle"
                        className=""
                        variant="primary"
                      >
                        Confirm Indent
                      </Button>
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="row">
                      <div className="col-lg-1"></div>
                      <div className="col-lg-5">
                        <div className="input-group">
                          <Nav.Item id="navAccord" className="navLink">
                            <Nav.Link
                              as={NavLink}
                              to=""
                              className="text-primary text-center mt-4 p-0"
                            >
                              Download Invoice
                            </Nav.Link>
                          </Nav.Item>{" "}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-group">
                          <Nav.Item id="navAccord" className="navLink">
                            <Nav.Link
                              as={NavLink}
                              to=""
                              className="text-primary text-center mt-4 p-0"
                            >
                              Send Email
                            </Nav.Link>
                          </Nav.Item>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Indent;
