import React from 'react';
import './App.css';
import LoginPage from './Components/AuthScreen/LoginPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import OrdersPage from './Components/OrdersPage/OrdersPage';
// import CreateNewOrder from './Components/OrdersPage/CreateNewOrder';
import JobCards from './Components/JobCardsPage/JobCards';
import CashReceiptPage from './Components/CashReceipt/CashReceiptPage';
import DashboardPage from './Components/DashboardPage/DashboardPage';
import AddNewJobCard from './Components/JobCardsPage/AddNewJobCard';
import Analytics from './Components/Analytics/Analytics';
// import AddCashReceipt from './Components/CashReceipt/AddCashReceipt';
import Estimates from './Components/OrdersPage/Estimates';
import Delivery from './Components/Delivery/Delivery';
import OrderTracking from './Components/OrderTracking/OrderTracking';
import UserOrderTracking from './Components/OrderTracking/UserOrderTracking';
import JobIDPage from './Components/JobCardsPage/JobIDPage';
import NewUserOrderTracking from './Components/AuthScreen/NewUserOrderTracking'
import { ToastContainer } from 'react-toastify';
import Clients from './Components/Clients/Clients';
// import EditOrder from './Components/OrdersPage/EditOrders';
import EditJobCard from './Components/JobCardsPage/EditJobCard';
// import ConfirmedOrders from './Components/OrdersPage/ConfirmedOrders';
import Printing from './Components/Printing/Printing';
import UserManagement from './Components/MasterData/UserManagemnet/UserManagement';
import EditEstimates from './Components/OrdersPage/EditEstimates';
import CreateNewEstimate from './Components/OrdersPage/CreateNewEstimate.js';
import CreateNewUser from './Components/MasterData/UserManagemnet/CreateNewUser';
import Indent from './Components/Indent/Indent';
import CreateClients from './Components/Clients/CreateClients';
import ClientDetails from './Components/Clients/ClientDetails';
import JobType from './Components/MasterData/JobType/JobType';
// import PaperType from './Components/MasterData/PaperType/PaperType';
// import PriceList from './Components/MasterData/PriceListJobWise/PriceList';
import Lamination from './Components/MasterData/Lamination/lamination';
import ExtraJobs from './Components/MasterData/ExtraJobs/ExtraJobs';
import EditUser from './Components/MasterData/UserManagemnet/EditUser';
import MillSize from './Components/MasterData/MillSize/millSize';
// import FinishSize from './Components/MasterData/FinishSize/FinishSize';
import PaperGsm from './Components/MasterData/PaperGsm/PaperGsm';
import OrderType from './Components/MasterData/OrderType/OrderType';
import ModeOfPrinting from './Components/MasterData/ModeOfPrinting/ModeOfPrinting';
import CreateVendor from './Components/MasterData/Vendor/CreateVendor';
import Vendor from './Components/MasterData/Vendor/Vendor';
import Punching from './Components/MasterData/punching/punching';
import Pasting from './Components/MasterData/Pasting/pasting';
import Cutting from './Components/MasterData/Cutting/cutting';
import Sidebar from './Components/Sidenav/Sidebar';
import PrintEstimate from './Components/OrderTracking/printEstimate';
import PrintIndent from './Components/Indent/PrintIndent';
import PrintReceipt from './Components/CashReceipt/PrintReceipt';
import NewEstimateCreate from './Components/OrdersPage/NewEstimateCreate';
import NewEditEstimate from './Components/OrdersPage/NewEditEstimate';
import CreateNewJobCard from './Components/JobCardsPage/CreateNewJobCard';
import EditNewJobCard from './Components/JobCardsPage/EditNewJobCard';
import PlateIndent from './Components/Indent/PlateIndent';
import PrintOrder from './Components/OrderTracking/printOrder';
import Transport from './Components/MasterData/Transport/Transport';
import PdfDocument from './Components/JobCardsPage/PdfDocument';



function App() {
  return (
    <>
    <ToastContainer />
    <BrowserRouter>
    <Routes>
        <Route path="/" element={ <LoginPage /> }></Route>
        <Route exact path='/orders' element={< OrdersPage />}></Route>  
        {/* <Route exact path='/createOrders' element={< CreateNewOrder />}></Route>   */}
        <Route exact path='/createEstimate' element={< CreateNewEstimate />}></Route>  
        {/* <Route exact path='/editorder' element={< EditOrder />}></Route>   */}
        <Route exact path='/editestimate' element={< EditEstimates />}></Route>  
        <Route exact path='/createjob' element={< AddNewJobCard />}></Route> 
        <Route exact path='/estimates' element={< Estimates />}></Route>   
        {/* <Route exact path='/ConfirmedOrders' element={< ConfirmedOrders />}></Route>    */}
        <Route exact path='/editjob' element={< EditJobCard />}></Route>  
        <Route exact path='/jobcards' element={< JobCards />}></Route>  
        <Route exact path='/receipt' element={< CashReceiptPage />}></Route>  
        <Route exact path='/userManagement' element={< UserManagement />}></Route>  
        <Route exact path='/createuser' element={< CreateNewUser />}></Route>  
        <Route exact path='/edituser' element={< EditUser />}></Route>  
        <Route exact path='/dashboard' element={< DashboardPage />}></Route>  
        <Route exact path='/analytics' element={< Analytics />}></Route>  
        {/* <Route exact path='/AddReceipt' element={< AddCashReceipt />}></Route>   */}
        <Route exact path='/delivery' element={< Delivery />}></Route>  
        <Route exact path='/OrderTracking' element={< OrderTracking />}></Route>  
        <Route exact path='/UserOrderTracking' element={< UserOrderTracking />}></Route>  
        <Route exact path='/JobId' element={< JobIDPage />}></Route>  
        <Route exact path='/Clients' element={< Clients />}></Route>  
        <Route exact path='/clientdetails' element={< ClientDetails />}></Route>  
        <Route exact path='/createclients' element={< CreateClients />}></Route>  
        <Route exact path='/printing' element={< Printing />}></Route>  
        <Route exact path='/jobtype' element={< JobType />}></Route>  
        {/* <Route exact path='/paperType' element={< PaperType />}></Route>   */}
        {/* <Route exact path='/priceList' element={< PriceList />}></Route>   */}
        <Route exact path='/newuserordertracking' element={< NewUserOrderTracking />}></Route>  
        <Route exact path='/vendor' element={< Vendor />}></Route>  
        <Route exact path='/punching' element={< Punching />}></Route>  
        <Route exact path='/pasting' element={< Pasting />}></Route>  
        <Route exact path='/cutting' element={< Cutting />}></Route>  
        <Route exact path='/transport' element={< Transport />}></Route>  
        <Route exact path='/modeofprinting' element={< ModeOfPrinting />}></Route>  
        <Route exact path='/lamination' element={< Lamination />}></Route>  
        <Route exact path='/millisize' element={< MillSize />}></Route>  
        {/* <Route exact path='/finishsize' element={< FinishSize />}></Route>   */}
        <Route exact path='/papergsm' element={< PaperGsm />}></Route>  
        <Route exact path='/extrajobs' element={< ExtraJobs />}></Route>  
        <Route exact path='/createvendor' element={< CreateVendor />}></Route>  
        <Route exact path='/indent' element={< Indent />}></Route>  
        <Route exact path='/ordertype' element={< OrderType />}></Route>  
        <Route exact path='/sidebar' element={< Sidebar />}></Route>  
        <Route exact path='/printestimate' element={< PrintEstimate />}></Route>  
        <Route exact path='/printorder' element={< PrintOrder/>}></Route>  
        <Route exact path='/printindent' element={< PrintIndent />}></Route>  
        <Route exact path='/plateindent' element={< PlateIndent />}></Route>  
        <Route exact path='/printreceipt' element={< PrintReceipt />}></Route>  
        <Route exact path='/newestimate' element={< NewEstimateCreate />}></Route>  
        <Route exact path='/neweditestimate' element={< NewEditEstimate />}></Route>  
        <Route exact path='/newjobcard' element={< CreateNewJobCard />}></Route>  
        <Route exact path='/editjobcard' element={< EditNewJobCard />}></Route>  
        <Route exact path='/pdfdocument' element={< PdfDocument />}></Route>  
    </Routes>
</BrowserRouter></>
  );
}

export default App;
