import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import "../../Styles/Sidebar.css";
import Accordion from "react-bootstrap/Accordion";
import EstimatesIcon from "../../Images/EstimatesIcon.svg";
import estimatesLight from "../../Images/estimatesLight.svg";
import DashIcon from "../../Images/DashIcon.png";
import DashIconLight from "../../Images/DashIconLight.png";
import OrdersIcon from "../../Images/OrdersIcon.png";
import OrdersLight from "../../Images/OrdersLight.png";
import JobIcon from "../../Images/JobIcon.png";
import JobLight from "../../Images/JobLight.png";
import ClientsIcon from "../../Images/ClientsIcon.png";
import ClientsLight from "../../Images/ClientsLight.png";
import PrintingIcon from "../../Images/PrintingIcon.png";
import PrintingLight from "../../Images/PrintingLight.png";
import PaymentsIcon from "../../Images/PaymentsIcon.png";
import PaymentsLight from "../../Images/PaymentsLight.png";
import AnalyticsIcon from "../../Images/AnalyticsIcon.png";
import AnalyticsLight from "../../Images/AnalyticsLight.png";
import DeliveryIcon from "../../Images/DeliveryIcon.png";
import DeliveryLight from "../../Images/DeliveryLight.png";
import ContactIcon from "../../Images/ContactIcon.png";
import GiftIcon from "../../Images/GiftIcon.png";
import LogoutIcon from "../../Images/LogoutIcon.png";
import MasterIcon from "../../Images/MasterIcon.png";
import IndentIcon from "../../Images/IndentIcon.svg";
import IndentLight from "../../Images/IndentLight.svg";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import get_userdetails from "../../redux/actions/getuserDetailsAction";

function Sidebar(props) {
  const [user, setUserdata] = useState();
  let location = useLocation();
  useEffect(() => {
    getuserdetails();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);

  const getuserdetails = async () => {
    console.log(props.userdata.user, "props.userdata.user");
    setUserdata(props.userdata.user);
  };
  console.log(props.userdata, "userdata");
  return (
    <>
      <Nav className="col-lg-2 col-sm-4 col-md-3 d-block sidebar shadow 100vh">
        <Nav.Item id="navAccord" className=" navLink rounded-4 mt-4 ">
          <Nav.Link className="" as={NavLink} to="/dashboard">
            {location.pathname === "/dashboard" ? (
              <img className="menuIcon mx-1" src={DashIconLight} />
            ) : (
              <img className="menuIcon mx-1" src={DashIcon} />
            )}
            &nbsp;&nbsp;Dashboard
          </Nav.Link>
        </Nav.Item>
        {user ? (
          user.access.includes("OB") || (user.access.includes("AA")) ? (
            <Nav.Item id="navAccord" className=" navLink rounded-4">
              <Nav.Link className="" as={NavLink} to="/estimates">
                {location.pathname === "/estimates" ? (
                  <img className="menuIcon mx-1" src={estimatesLight} />
                ) : (
                  <img className="menuIcon mx-1" src={EstimatesIcon} />
                )}
                &nbsp;&nbsp;Estimates
              </Nav.Link>
            </Nav.Item>
          ) : null
        ) : null}
        {user ? (
          user.access.includes("OB") || (user.access.includes("AA")) ? (
            <Nav.Item id="navAccord" className=" navLink rounded-4">
              <Nav.Link className="" as={NavLink} to="/orders">
                {location.pathname === "/orders" ? (
                  <img className="menuIcon mx-1" src={OrdersLight} />
                ) : (
                  <img className="menuIcon mx-1" src={OrdersIcon} />
                )}
                &nbsp;&nbsp;Orders
              </Nav.Link>
            </Nav.Item>
          ) : null
        ) : null}
        {/* <Nav.Item className="">
          <Accordion className="accord my-0">
            <Accordion.Item eventKey="0" className="navLink py-0" id="sideaccord">
              <Accordion.Header ><img className="menuIcon" src={OrdersIcon} />&nbsp;&nbsp;&nbsp;<span className="orderstab">Orders</span></Accordion.Header>
              <Accordion.Body id="accordion-body">
                <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link as={NavLink} to='/estimates' eventKey="link-2">All Estimates</Nav.Link>
                  <Nav.Link as={NavLink} to='/createOrders' eventKey="link-1">Create New order</Nav.Link>
                  <Nav.Link as={NavLink} to='/Orders'>All Orders</Nav.Link>
                  <Nav.Link as={NavLink} to='/ConfirmedOrders' eventKey="link-3">Confirmed Orders</Nav.Link>
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Nav.Item> */}
        {user ? (
          user.access.includes("JC") || (user.access.includes("AA")) ? (
            <Nav.Item id="navAccord" className="navLink">
              <Nav.Link as={NavLink} to="/jobcards">
                {location.pathname === "/jobcards" ? (
                  <img className="menuIcon mx-1" src={JobLight} />
                ) : (
                  <img className="menuIcon mx-1" src={JobIcon} />
                )}
                &nbsp;&nbsp;Jobcards
              </Nav.Link>
            </Nav.Item>
          ) : null
        ) : null}

        {/* {user ? user.access.includes('PM') ?
          <Nav.Item id="navAccord" className="navLink">
            <Nav.Link as={NavLink} to='/printing'>{location.pathname === '/printing' ? <img className="menuIcon mx-1" src={PrintingLight} /> : <img className="menuIcon mx-1" src={PrintingIcon} />}&nbsp;&nbsp;Printing</Nav.Link>
          </Nav.Item> : null : null
        } */}

        {/* {user ? user.access.includes('MI') ?
          <Nav.Item id="navAccord" className="navLink">
            <Nav.Link as={NavLink} to='/indent' >{location.pathname === '/indent' ? <img className="menuIcon mx-1" src={IndentLight} /> : <img className="menuIcon mx-1" src={IndentIcon} />}&nbsp;&nbsp;Indents</Nav.Link>
          </Nav.Item> : null : null
        } */}
        <Nav.Item id="navAccord" className="navLink">
          <Nav.Link as={NavLink} to="/indent">
            {location.pathname === "/indent" ? (
              <img className="menuIcon mx-1" src={IndentLight} />
            ) : (
              <img className="menuIcon mx-1" src={IndentIcon} />
            )}
            &nbsp;&nbsp;Indents
          </Nav.Link>
        </Nav.Item>
        {/* {user ? user.access.includes('PM') ?
          <Nav.Item id="navAccord" className="navLink">
            <Nav.Link as={NavLink} to='/printing'>{location.pathname === '/printing' ? <img className="menuIcon mx-1" src={PrintingLight} /> : <img className="menuIcon mx-1" src={PrintingIcon} />}&nbsp;&nbsp;Printing</Nav.Link>
          </Nav.Item> : null : null
        } */}
        {user ? (
          user.access.includes("CR") || (user.access.includes("AA")) ? (
            <Nav.Item id="navAccord" className="navLink">
              <Nav.Link as={NavLink} to="/receipt">
                {location.pathname === "/receipt" ? (
                  <img className="menuIcon mx-1" src={PaymentsLight} />
                ) : (
                  <img className="menuIcon mx-1" src={PaymentsIcon} />
                )}
                &nbsp;&nbsp;Receipts
              </Nav.Link>
            </Nav.Item>
          ) : null
        ) : null}

        {user ? (
          user.access.includes("DM") || (user.access.includes("AA")) ? (
            <Nav.Item id="navAccord" className="navLink">
              <Nav.Link as={NavLink} to="/delivery">
                {location.pathname === "/delivery" ? (
                  <img className="menuIcon mx-1" src={DeliveryLight} />
                ) : (
                  <img className="menuIcon mx-1" src={DeliveryIcon} />
                )}
                &nbsp;&nbsp;Delivery
              </Nav.Link>
            </Nav.Item>
          ) : null
        ) : null}
        {/* <Nav.Item id="navAccord" className="navLink">
          <Nav.Link as={NavLink} to='/analytics'>{location.pathname === '/analytics' ? <img className="menuIcon mx-1" src={AnalyticsLight} />:<img className="menuIcon mx-1" src={AnalyticsIcon} />}&nbsp;&nbsp;Analytics</Nav.Link>
        </Nav.Item> */}

        {/* {user ? user.access.includes('CM') ?
          <Nav.Item id="navAccord" className="navLink">
            <Nav.Link as={NavLink} to='/Clients'>{location.pathname === '/Clients' ? <img className="menuIcon mx-1" src={ClientsLight} /> : <img className="menuIcon mx-1" src={ClientsIcon} />} &nbsp;&nbsp;Clients</Nav.Link>
          </Nav.Item> : null : null
        } */}
        {user ? (
          user.access.includes("UM") || (user.access.includes("AA")) ? (
            <Nav.Item id="navAccord" className="navLink">
              <Nav.Link as={NavLink} to="/userManagement">
                {location.pathname === "/userManagement" ? (
                  <img className="menuIcon mx-1" src={ClientsLight} />
                ) : (
                  <img className="menuIcon mx-1" src={ClientsIcon} />
                )}{" "}
                &nbsp;&nbsp;User Management
              </Nav.Link>
            </Nav.Item>
          ) : null
        ) : null}
        {/* {user ? user.access.includes('UM') ? 
            <Nav.Link as={NavLink} to='/userManagement'>User Management</Nav.Link> : null : null
        } */}

        {user ? (
          user.access.includes("MD") || (user.access.includes("AA")) ? (
            <Nav.Item className="">
              <Accordion className="accord my-0">
                <Accordion.Item
                  eventKey="1"
                  className="navLink"
                  id="sideaccord"
                >
                  <Accordion.Header>
                    <img className="menuIcon" src={MasterIcon} />
                    &nbsp;&nbsp;&nbsp;<span className="orderstab">Master</span>
                  </Accordion.Header>
                  <Accordion.Body id="accordion-body1">
                    <Nav defaultActiveKey="/home" className="flex-column">
                      <Nav.Link as={NavLink} to="/Clients">
                        Clients
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/jobtype">
                        Job Type
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/papergsm">
                        Paper Gsm
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/millisize">
                        Mill Size
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/cutting">
                        Cutting Size
                      </Nav.Link>
                      {/* <Nav.Link as={NavLink} to='/finishsize' >Finish Size</Nav.Link> */}
                      <Nav.Link as={NavLink} to="/modeofprinting">
                        Mode Of Printing
                      </Nav.Link>
                      {/* <Nav.Link as={NavLink} to='/priceList' >Pricing List</Nav.Link> */}
                      <Nav.Link as={NavLink} to="/lamination">
                        Lamination
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/punching">
                        Punching
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/pasting">
                        Pasting
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/vendor">
                        Vendors
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/transport">
                        Transport
                      </Nav.Link>
                      {/* <Nav.Link as={NavLink} to='/userManagement' >User management</Nav.Link> */}
                      {/* <Nav.Link as={NavLink} to='/paperType' >Paper Type</Nav.Link> */}
                      {/* <Nav.Link as={NavLink} to="/extrajobs">
                        Extra Jobs
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/ordertype">
                        order type
                      </Nav.Link> */}
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Nav.Item>
          ) : null
        ) : null}

        <Nav.Item id="navAccord" className="navcontact mt-2">
          <Nav.Link className="text-dark">
            <img className="menuIcon  mx-1" src={ContactIcon} />
            &nbsp;&nbsp;Contact Support
          </Nav.Link>
        </Nav.Item>
        <Nav.Item id="navAccord" className="mt-2 navgift">
          <Nav.Link className="text-dark">
            <img className="menuIcon mx-1" src={GiftIcon} />
            &nbsp;&nbsp;Free Gift Awaits You
          </Nav.Link>
          <p className="mx-1 text-lg-center px-4 px-lg-0 pb-2">
            Upgrade Your Account
          </p>
        </Nav.Item>
        <Nav.Item id="navAccord" className="mb-5">
          <Nav.Link as={NavLink} to="/" className="text-danger">
            <img className="menuIcon mx-1" src={LogoutIcon} />
            &nbsp;&nbsp;Logout
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userdata: state.userdetails,
  };
};

export default connect(mapStateToProps)(Sidebar);
