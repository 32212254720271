import React, { useEffect, useState } from "react";
import "../../../Styles/JobCards.css";
import Header from "../../header/Header";
import Sidebar from "../../Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../../Images/filterIcon.png";
import shareIcon from "../../../Images/shareIcon.png";
import sortIcon from "../../../Images/sortIcon.png";
import calenderIcon from "../../../Images/calenderIcon.png";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import Table from 'react-bootstrap/Table';
import { NavLink } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate, useLocation } from "react-router";
import Modal from "react-bootstrap/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import estimateService from "../../../services/estimateService";
import MasterService from "../../../services/MasterService";
import { toast } from "react-toastify";
import axios from "axios";
import editIcon from "../../../Images/editIcon.svg";
import { Table, Pagination } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import authService from "../../../services/authService";
import deleteIconRed from "../../../Images/deleteIconRed.svg";
import { connect } from "react-redux";
import get_userdetails from "../../../redux/actions/getuserDetailsAction";

const Cutting = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  let location = useLocation();
  const [user, setUserdata] = useState();
  useEffect(() => {
    getuserdetails();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);

  const getuserdetails = async () => {
    console.log(props.userdata.user, "props.userdata.user");
    setUserdata(props.userdata.user);
  };
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cutcount, setCutcount] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [search, setSearch] = useState(false);
  const [serachh, setSerachh] = useState(false);
  const [reset, setReset] = useState(false);

  const handleChangePage = (event, newPage) => {
    const dett = newPage * rowsPerPage;
    setPage(newPage);
    getcutting(dett, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getcutting(0, event.target.value);
  };
  let navigate = useNavigate();

  const [modalShow, setModalShow] = React.useState(false);
  const [editmodal, setEditModalShow] = React.useState(false);

  const [cutting, setCutting] = useState([]);
  useEffect(() => {
    getcutting(0, 10);
  }, []);

  const getcutting = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        //  "searchkeys": ''
      };
      const dert = await estimateService.getcuttingsize(obj);
      console.log(dert, "dert");
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setCutting(dert.data.value);
        setCutcount(dert.data.count);
      }
    } catch (ex) {}
  };

  const [sbtndis, setSBtndis] = useState(false);
  const [role, setRole] = useState("");
  const [id, setID] = useState("");
  const [edetails, setEDetails] = useState("");
  const [details, setDetails] = useState("");
  const [elength, setELength] = useState("");
  const [length, setLength] = useState("");
  const [ebreadth, setEBreadth] = useState("");
  const [breadth, setBreadth] = useState("");
  const [cost, setCost] = useState("");
  const [rate, setRate] = useState("");

  // const [status, setStatus] = useState('');
  const handleDetail = (event) => {
    const { value } = event.target;
    const dValue =value.replace(/[^0-9.*!,-]/g, '');
    setDetails(dValue);
  };
  const handleCLength = (event) => {
    const { value } = event.target;
    setLength(value.replace(/[^0-9.*!,-]/g, ''));
    };
  const handleCBreath = (event) => {
    const { value } = event.target;
    setBreadth(value.replace(/[^0-9.*!,-]/g, ''));
    };

  const create = async () => {
   
    if (!details) {
      await setSBtndis(false);
      setEDetails("Cutting Details Is Required!");
      toast.error("Enter Cutting Details", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!length) {
      await setSBtndis(false);
      setEDetails("");
      setELength("Cutting Length is Required!");
      toast.error("Enter Length", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!breadth) {
      await setSBtndis(false);
      setEDetails("");
      setELength("");
      setEBreadth("Cutting Breadth is Required!");
      toast.error("Enter Breadth", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      
    } else {
      setEDetails("");
      setELength("");
      setEBreadth("");
    try {
      setIsLoading(true);
      const obj = {
        cuttingSize: details,
        length: length,
        breadth: breadth,
        // "active": status
      };
      console.log(obj, "objjj");
      const dert = await MasterService.addcutting(obj);
      console.log(dert.data, "dert.data");
      if (200 === 200) {
        toast.success("Cutting Size Created Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate("/cutting");
        getcutting(0, 10);
        setBreadth("");
        setLength("");
        setDetails("");
        setModalShow(false);
        
      } else {
        toast.error(dert.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      if (ex.response.status === 400) console.log(ex.response, "ex.response");
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    finally{
      setIsLoading(false);
    }}
  };

  const Delete = async (item) => {
    try {
      const obj = {
        _id: item._id,
      };

      const dert = await MasterService.deletecutting(obj);
      console.log(dert, "dert");

      if (dert.data.message) {
        getcutting(0, 10);
         toast.success("Cutting Size Deleted Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
     
        // setSearch(true)
        console.log(dert.data.value, "values");

        // setBreadth('');
        // setLength('');
        // setDetails('')
      }
    } catch (ex) {}
  };

  console.log(user, "accessssss");
  // download Excel

  const [isDownloading, setIsDownloading] = useState(false);

  // const handleDownloadExcel = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const response = await axios.post(
  //       "/newMaster/cuttingSize/jsontoexcel",
  //       { data: [] },
  //       { responseType: "blob" }
  //     );
  //     const excelBlob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(excelBlob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "Cutting.xlsx";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const requestData = {
        // searchkeys : searchtext   
        searchkeys: { cuttingSize: searchtext },
   
      };
      const response = await axios.post(
        "/newMaster/cuttingSize/jsontoexcel",
        requestData,
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Cutting.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  //  Download Excel Close

  //pagination
  //     const [currentPage, setCurrentPage] = useState(1);
  //     const handlePageChange = (pageNumber) => {
  //         setCurrentPage(pageNumber);
  //     }

  //     const itemsPerPage = 5; // Change this as needed
  //     const totalItems = obj.length; // Replace myData with your data array
  //     const totalPages = Math.ceil(totalItems / itemsPerPage);

  //     const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const currentItems = obj.slice(startIndex, endIndex);

  //pagination close

  //edit integration
  const [edmill, setedmill] = useState({});
  const [cutdetails, setCutDetails] = useState("");
  const [ecutdetails, setEcutDetails] = useState("");
  const [cutlength, setCutLength] = useState("");
  const [ecutlength, setEcutLength] = useState("");
  const [cutbreadth, setCutBreadth] = useState("");
  const [ecutbreadth, setEcutBreadth] = useState("");
  // const [cutstatus, setCutStatus] = useState('');
  const handleChangeCut = (event) => {
    const { value } = event.target;
    const sdValue = value.replace(/[^0-9.*!,-]/g, '');
    setCutDetails(sdValue);
  };
  const handleCutLen = (event) => {
    const { value } = event.target;
    setCutLength(value.replace(/[^0-9.*!,-]/g, ''));
      };
  const handleCutBreath = (event) => {
    const { value } = event.target;
    setCutBreadth(value.replace(/[^0-9.*!,-]/g, ''));
    };

  const clickedit = (k) => {
    setedmill(k);
    setCutDetails(k.cuttingSize);
    setCutLength(k.length);
    setCutBreadth(k.breadth);
    // setCutStatus(k.active)
    setEditModalShow(true);
  };
  const handlesubmit = async () => {
   
    if (!cutdetails) {
      await setSBtndis(false);
      setEcutDetails("Cutting Details Is Required!");
      toast.error("Enter Cutting Details", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!cutlength) {
      await setSBtndis(false);
      setEcutDetails("");
      setEcutLength("Cutting Length Is Required!");
      toast.error("Enter Cutting Length", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!cutbreadth) {
      await setSBtndis(false);
      setEcutDetails("");
      setEcutLength("");
      setEcutBreadth("Cutting Breadth Is Required!");
      toast.error("Enter Cutting Breadth", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // } else if (!cutstatus) {
      //     await setSBtndis(false)
      //     toast.error('Select Status', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else {
      setEcutDetails("");
      setEcutLength("");
      setEcutBreadth("");
    
    try {
      setIsLoading(true);
      const obj = {
        _id: edmill._id,
        cuttingSize: cutdetails,
        // "active": cutstatus,
        length: cutlength,
        breadth: cutbreadth,
        quantity: 0,
        isActive: true,
      };
      const dort = await MasterService.updatecutting(obj);
      if (dort.status === 200) {
        getcutting(0, 10);
        await setSBtndis(false);
        navigate("/cutting");
        setEditModalShow(false);
       
        toast.success("Cutting Size Updated Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      await setSBtndis(false);
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }finally{
      setIsLoading(false);
    }}
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      serachclick();
     
    }
  };
  //edit integration close

  const searchChange = (value) => {
    setSearchText(value);
    // getestimates(0,10)
  };
  const serachclick = async () => {
    // getestimates(0, 10)
    const trimmedSearchText = searchtext.trim(); 
    if (trimmedSearchText === "") {
      toast.error("Enter Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    }
     else {
      try {
        const obj = {
          skip: 0,
          limit: rowsPerPage,
          searchkeys: { cuttingSize: trimmedSearchText },
        };
        const dert = await estimateService.getcuttingsize(obj);
        console.log(dert, "dert");
        if (dert.data.status === "success") {
          setSearch(true);
          console.log(dert.data.value, "values");
          setCutting(dert.data.value);
          setCutcount(dert.data.count);
        }
      } catch (ex) {}
    }
  };
  const resetclick = () => {
    setSearchText("");
    setSearch(false);
    getcutting(0, rowsPerPage);
  };
  function modalreset() {
    setDetails("");
    setBreadth("");
    setLength("");
    setModalShow(false);
    setEcutDetails("");
      setEcutLength("");
      setEcutBreadth("");
      setEDetails("");
      setELength("");
      setEBreadth("");
  }
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={4} className="containerr">
            <div className="d-flex">
              <div className="col-lg-9 col-sm-9">
                <h1 className="fs-3 mx-5 mt-5">Cutting</h1>
                <Breadcrumb className="mx-5 fs-14">
                  <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Cutting</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="col-lg-2 col-sm-2 mt-5">
                <Button
                  onClick={() => setModalShow(true)}
                  id="pillStyle"
                  className="btnnew"
                  variant="primary"
                >
                  Create Cutting
                </Button>
              </div>
            </div>
            <Row className="d-flex">
              <Col lg={12} sm={12} md={6} xs={12} className="mb-5 datacenter">
                <Paper className="col-lg-12 col-sm-8 rounded-4">
                  <Box sx={{}}>
                    <Row>
                      <Col lg={4} sm={12} className="text-lg-start ">
                        <h6 className="datahead text-muted">Cutting List</h6>
                      </Col>
                      {/* categories starts */}
                      <Col lg={8} sm={12} >
                        <Row className="d-flex justify-content-end ">
                          <Col lg={5} sm={6}>
                            <Form className="mt-3 d-flex gap-2 searchField">
                              <div className="col-lg-8 d-flex">
                                <input
                                  value={searchtext}
                                  onChange={(e) => searchChange(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  className="form-control nosubmit"
                                  style={{ fontSize: "14px" }}
                                  type="search"
                                  placeholder="Search Cutting size"
                                />
                              </div>
                              <div className="col-lg-4">
                                {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Search
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}
                                {/* <span className="searchcont" onClick={() => serachclick()} style={{cursor:'pointer'}}><i class="fa fa-search empsearch"></i></span> */}
                              </div>
                            </Form>
                          </Col>
                        
                          {/* Export Excel */}
                          <Col lg={3} sm={2}>
                            <Button
                              onClick={handleDownloadExcel}
                              disabled={isDownloading}
                              id="shareIcon"
                              variant="outlined"
                            >
                              <img
                                className="menuIcon mx-1 mb-1"
                                src={shareIcon}
                              />
                              {isDownloading ? "Downloading" : "Export Excel"}
                            </Button>
                          </Col>
                          {/* Bulk Select */}
                          {/* <Col lg={3} sm={2}>
                                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                                            <option hidden className='text-center'>Bulk Action</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </Col> */}
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <Form.Check aria-label="option 1" /></div>
                                                    </th> */}
                          <th>
                            <div className="table-headFilter ps-2">
                              <span>#</span>
                            </div>
                          </th>

                          <th>
                            <div className="table-headFilter">
                              <span>Cutting Details</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>

                          <th>
                            <div className="table-headFilter">
                              <span>Length</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Breadth</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <span>Status</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th> */}
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {cutting && cutting.length > 0
                          ? cutting.map((k, index) => {
                              return (
                                <tr>
                                  {/* <Form.Check aria-label="option 1" /> */}
                                  <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                  <td>{k.cuttingSize}</td>
                                  <td>{k.length}</td>
                                  <td>{k.breadth}</td>
                                  {/* <td>{k.active === true ? 'Active' : 'In Active'}</td> */}
                                  <td onClick={() => clickedit(k)} className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={editIcon}
                                    />
                                    Edit
                                  </td>
                                  {user ? (
                                    props.userdata.user.access.includes(
                                      "AA"
                                    ) ? (
                                      <td onClick={() => Delete(k)} className="hand-cursor">
                                        <img
                                          className="menuIcon mx-1"
                                          src={deleteIconRed}
                                        />
                                        Delete
                                      </td>
                                    ) : null
                                  ) : null}
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        {/* <Pagination
                                                    className="justify-content-center"
                                                    size="sm"
                                                    activePage={currentPage}
                                                    itemsCountPerPage={itemsPerPage}
                                                    totalItemsCount={totalItems}
                                                    pageRangeDisplayed={5}
                                                    onChange={handlePageChange}
                                                /> */}
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={cutcount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Modal
            show={modalShow}
            onHide={() => modalreset()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            class="modal-dialog modal-dialog-centered"
            // style={{  marginLeft: '30%' }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Add Cutting Size
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card className="rounded-4">
                <CardContent>
                  <div className="row">
                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group">
                          <TextField
                            className="w-100"
                            value={details}
                            onChange={(e) => setDetails(e.target.value)}
                            id="inputcolor"
                            type="text"
                            label="Cutting*"
                            variant="outlined"
                          />
                        </div>
                        <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {edetails ? edetails : null}
                      </div>
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mt-3">
                          <TextField
                            className="w-100"
                            value={length}
                            onChange={handleCLength}
                            id="inputcolor"
                            type="text"
                            label="Length*"
                            variant="outlined"
                          />
                        </div>
                        <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {elength ? elength : null}
                      </div>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mt-3">
                          <TextField
                            className="w-100"
                            value={breadth}
                            onChange={handleCBreath}
                            id="inputcolor"
                            type="text"
                            label="Breadth*"
                            variant="outlined"
                          />
                        </div>
                        <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {ebreadth ? ebreadth : null}
                      </div>
                      </div>

                      {/* <div className="input-group">
                                                    <select value={status} onChange={(e) => setStatus(e.target.value)} className="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                                        <option selected hidden>Status*</option>
                                                        <option value={true}>Active</option>
                                                        <option value={false}>InActive</option>
                                                    </select>
                                                </div> */}
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <Button
                            id="pillStyle"
                            onClick={() => modalreset()}
                            value="modalreset"
                            className="w-100"
                            variant="outline-primary"
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="col-lg-6">
                        {!isLoading ? (
                          <Button
                            onClick={() => create()}
                            id="pillStyle"
                            className="w-100"
                            variant="primary"
                          >
                            Create Cutting size
                          </Button>
                           ) : (
                            <div className="loader mt-2" style={{marginLeft : '90px'}}></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Modal.Body>
          </Modal>
        </Row>
      </Container>

      <Modal
        show={editmodal}
        onHide={() => setEditModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Cutting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className=" rounded-4">
            <CardContent>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group">
                      <TextField
                        className="w-100"
                        value={cutdetails}
                        onChange={(e) => setCutDetails(e.target.value)}
                        id="inputcolor"
                        type="text"
                        label="Cutting*"
                        variant="outlined"
                      />
                    </div>
                    <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {ecutdetails ? ecutdetails : null}
                      </div>
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mt-3">
                      <TextField
                        className="w-100"
                        value={cutlength}
                        onChange={handleCutLen}
                        id="inputcolor"
                        type="text"
                        label="Length*"
                        variant="outlined"
                      />
                    </div>
                    <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {ecutlength ? ecutlength : null}
                      </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mt-3">
                      <TextField
                        className="w-100"
                        value={cutbreadth}
                        onChange={handleCutBreath}
                        id="inputcolor"
                        type="text"
                        label="Breadth*"
                        variant="outlined"
                      />
                    </div>
                    <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {ecutbreadth ? ecutbreadth : null}
                      </div>
                  </div>

                  {/* <div className="input-group">
                                        <select value={cutstatus} onChange={(e) => setCutStatus(e.target.value)} className="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Status*</option>
                                            <option value={true}>Active</option>
                                            <option value={false}>InActive</option>
                                        </select>
                                    </div> */}
                  <div className="row mt-3">
                    <div className="col-lg-6">
                      <Button
                        onClick={() => setEditModalShow(false)}
                        id="pillStyle"
                        className="w-100"
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-lg-6">
                    {!isLoading ? (
                      <Button
                        onClick={() => handlesubmit()}
                        id="pillStyle"
                        className="w-100"
                        variant="primary"
                      >
                        Update Cutting Size
                      </Button>
                       ) : (
                        <div className="loader mt-2" style={{marginLeft : '90px'}}></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userdata: state.userdetails,
  };
};

export default connect(mapStateToProps)(Cutting);
