import React, { useState, useEffect, useRef } from "react";
import "../../Styles/JobCards.css";
import Header from "../header/Header";
import Sidebar from "../Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../Images/filterIcon.png";
import shareIcon from "../../Images/shareIcon.png";
import calenderIcon from "../../Images/calenderIcon.png";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Table from "react-bootstrap/Table";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import sortIcon from "../../Images/sortIcon.png";
import eyeIcon from "../../Images/eyeIcon.svg";
import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import SalesIcon from "../../Images/SalesIcon.png";
import DashOrdersIcon from "../../Images/DashOrdersIcon.png";
import TablePagination from "@mui/material/TablePagination";
import Modal from "react-bootstrap/Modal";
import TrashIcon from "../../Images/TrashIcon.svg";
import ReceiptService, {
  getreceipts,
  addreceipts,
} from "../../services/ReceiptService";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import ClientsService from "../../services/ClientsService";
import Multiselect from "multiselect-react-dropdown";
import get_userdetails from "../../redux/actions/getuserDetailsAction";
import UploadImageIcon from "../../Images/UploadImageIcon.png";
import uploadCloudImage from "../../Images/uploadCloudImage.png";
import deliveryService, { getdeliver } from "../../services/deliveryService";
import estimateService from "../../services/estimateService";
import JobcardsService from "../../services/JobcardsService";
import Moment from "moment";
import PropTypes from 'prop-types';

const Delivery = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState();
  const [id, setID] = useState();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [delcount, setDelcount] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [search, setSearch] = useState(false);
  const [remarks, setRemarks] = useState('');

  const handleChangePage = (event, newPage) => {
    const dett = newPage * rowsPerPage;
    setPage(newPage);
    getdeliver(dett, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getdeliver(0, event.target.value);
  };
  let navigate = useNavigate();

  const [modalShow, setModalShow] = React.useState(false);
  const [editmodalShow, setEditModalShow] = React.useState(false);
  const [delivery, setDeliver] = useState([]);
  useEffect(() => {
    getdeliver(0, 10);
  }, []);

  const resetorder = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        searchkeys: '',
       
      };
      const dert = await deliveryService.getdeliver(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "valuesss");
        setDeliver(dert.data.value);
        setDelcount(dert.data.count);
      }
    } catch (ex) {}
  };

  const getdeliver = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        searchkeys: { customerName: searchtext },
      };
      const dert = await deliveryService.getdeliver(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "valuesss");
        setDeliver(dert.data.value);
        setDelcount(dert.data.count);
      }
    } catch (ex) {}
  };


  // download Excel
  // console.log(location.state.item, 'location')
  const [isDownloading, setIsDownloading] = useState(false);

  // const handleDownloadExcel = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const response = await axios.post(
  //       "/delivers/jsontoexcel",
  //       { data: [] },
  //       { responseType: "blob" }
  //     );
  //     const excelBlob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(excelBlob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "Receipts.xlsx";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const requestData = {
        // Add your request object properties here
        // For example:
        // searchkeys: searchtext,
        searchkeys: { customerName: searchtext },
       
      };
      const response = await axios.post(
        "/delivers/jsontoexcel",
        requestData,
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Delivery.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  //  Download Excel Close

  //get clients integration
  const [client, setClient] = useState();
  const [clients, setClients] = useState([]);
  const [user, setUserdata] = useState({});
  const multiselectRef = useRef();

  useEffect(() => {
    getuserdetails();
    getclients();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);
  const getuserdetails = async () => {
    setUserdata(props.userdata.user);
  };
  

    //add cash receipt Integration
    const [cname, setCname] = useState('');
    const [newcname, setNewCname] = useState('');
    const [Cid, setCid] = useState('');
    const [orId, setorid] = useState('')
    const [email, setEmail] = useState('');
    const [newemail, setNewEmail] = useState('');
    const [phonecode, setPhoneCode] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneno, setPhoneNo] = useState('');
    const [address, setaddress] = useState('');
    const [newaddress, setNewaddress] = useState('');
    const [paytype, setPayType] = useState('');
    const [gstNo, setGstNo] = useState('');
    const [newgstNo, setNewGstNo] = useState('');
    const [transid, setTransID] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [newtime, setNewTime] = useState('');
    const [invoiceid, setInvoiceID] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [orders, setOrders] = useState([]);
    const [sumtotal, setSumTotal] = useState('');
    const [paidAmount, setPaidAmount] = useState('');
    const [pendingAmount, setPendingAmount] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [subTotalAmount, setSubTotalAmount] = useState('');
    const [sbtndis, setSBtndis] = useState(false);
    const [estimatecalc, setEstimatecalc] = useState();
    const [lrNumber, setlrNumber] = useState('');
    const [newlrNumber, setNewlrNumber] = useState('');
    const [createdByDate, setcreatedByDate] = useState();
    const [updatedDate, setupdatedDate] = useState();
    const [status, setstatus] = useState('');
    const [ustatus, setUStatus] = useState('');
    const [orderId, setOrderId] = useState('');
    const [imloader, setImloader] = useState(null);
    const [file, setFile] = useState(null);
    const hiddenFileInput = useRef(null);
    const [estmid, setEstmid] = useState('');
    const [customerId, setCustomerId] = useState('')
    const [custId, setCustId] = useState('')
    const [isResetVisible, setIsResetVisible] = useState(false);
    const location = useLocation();
    const [isSearchDisabled, setIsSearchDisabled] = useState(true);
    const [edmill, setedmill] = useState({});
    const create = async () => {
      if (!cname) {
        await setSBtndis(false);
        toast.error("Select Client Name", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else if (!orderId) {
        await setSBtndis(false);
        toast.error("Select OrderID", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      else if (!phone) {
        await setSBtndis(false);
        toast.error("Enter Mobile Number", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else if (phone.length < 10 && phone.length > 10) {
        await setSBtndis(false);
        toast.error("Invalid Phone", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } 
      // else if (!email) {
      //   await setSBtndis(false);
      //   toast.error("Enter Email", {
      //     position: toast.POSITION.BOTTOM_LEFT,
      //   });
      // } else if (!gstNo) {
      //   await setSBtndis(false);
      //   toast.error("Enter GST Number", {
      //     position: toast.POSITION.BOTTOM_LEFT,
      //   });
      // } else if (!lrNumber) {
      //   await setSBtndis(false);
      //   toast.error("Enter LR Number", {
      //     position: toast.POSITION.BOTTOM_LEFT,
      //   });
       else if (!address) {
        await setSBtndis(false);
        toast.error("Enter Address", {
          position: toast.POSITION.BOTTOM_LEFT,
        });


      } 
      else {
        try {
          setIsLoading(true);
            const obj = {
                "customerName": cname,
                "customerId": Cid,
                "orId": orId,
                "orderId": orderId,
                "phone": phone,
                "email": email,
                "gstNo": gstNo,
                "deliverDate": date,
                "deliverTime": time,
                "address": address,
                "lrNo": lrNumber,
            }

      const dert = await deliveryService.adddeliver(obj);
      console.log(dert, "dert");

      if (dert.data.data) {
        toast.success("Delivery Added SuccessFully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        console.log(dert.data, "dert.data");
        getdeliver(0, 10);
        setlrNumber("");
        setaddress("");
        setGstNo("");
        setEmail("");
        setPhone("");
        setOrderId("");
        navigate("/delivery");
        closemodal();
        setModalShow(false);
        // setIsLoading(false);
      }
    } catch (ex) {
      await setSBtndis(false);
      console.log(ex.response, "ex.response");
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    finally{
      setIsLoading(false);
    }
  }
  };

  function modalreset() {
    setlrNumber("");
    setaddress("");
    setGstNo("");
    setEmail("");
    setPhone("");
    setOrderId("");
    setRemarks('')
    setFile('')
    setModalShow(false);
  }

  const update = async () => {};
  // const update = async () => {
  //     try {
  //         console.log("status==========",status)
  //         console.log("ustatus==========",ustatus)
  //         const obj = {
  //             _id: edmill._id,
  // "customerName": newcname,
  // "customerId": Cid,
  // "orId": orId,
  //  "orderId": orderId,
  // "phone": phone,
  // "email": email,
  // "gstNo": gstNo,
  // "deliverDate": date,
  // "deliverTime": time,
  // "address": address,
  // "lrNo": lrNumber,
  //         "status": status
  //     }

  //     const dort = await deliveryService.updateDeliver(obj);
  //     if (dort.status === 200) {
  //         getdeliver(0, 10)
  //         await setSBtndis(false)
  //         navigate('/delivery')
  //         setEditModalShow(false)
  //         toast.success('Delivery Updated Successfully', {
  //             position: toast.POSITION.BOTTOM_LEFT
  //         });
  //     }
  // } catch (ex) {
  //     await setSBtndis(false)
  //     toast.error(ex.response.data.message, {
  //         position: toast.POSITION.BOTTOM_LEFT
  //     });
  // }}

  const deliveyupdate = async () => {
    if (!cname) {
      await setSBtndis(false);
      toast.error("Select Client Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!orderId) {
      await setSBtndis(false);
      toast.error("Select OrderID", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    else if (!phone) {
      await setSBtndis(false);
      toast.error("Enter Mobile Number", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (phone.length < 10 && phone.length > 10) {
      await setSBtndis(false);
      toast.error("Invalid Phone", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } 
    // else if (!email) {
    //   await setSBtndis(false);
    //   toast.error("Enter Email", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    // } else if (!gstNo) {
    //   await setSBtndis(false);
    //   toast.error("Enter GST Number", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    // } else if (!lrNumber) {
    //   await setSBtndis(false);
    //   toast.error("Enter LR Number", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
     else if (!address) {
      await setSBtndis(false);
      toast.error("Enter Address", {
        position: toast.POSITION.BOTTOM_LEFT,
      });


    } 
    try {
      setIsLoading(true);
        let seldate=null
        if(date !="" && date !="Invalid date"){
            seldate= (Moment(date).format('YYYY-MM-DD'))
        }
        const obj = {
            _id: edmill._id,
             "customerName": newcname,
             "customerId": Cid,
             "orId": orId,
             "orderId": orderId,
             "phone": phoneno,
             "email": newemail,
             "gstNo": newgstNo,
             "deliverDate":seldate,
             "deliverTime": newtime,
            "address": newaddress,
             "lrNo": newlrNumber,
    // "_id": "643d18db36f99c0f36b11f7d",
    "updatedDate": (Moment(updatedDate).format('YYYY-MM-DD')),
     "createDate": (Moment(createdByDate).format('YYYY-MM-DD')),
    "__v": 0
    }
    const dert = await deliveryService.updateDelivery(obj);
        if (dert.data.status === 'success') {
            getdeliver(0,10);
             toast.success('Delivery Updated Successfully', {
                position: toast.POSITION.BOTTOM_LEFT
            });
            console.log(dert.data.value, 'values');
            setDeliver(dert.data.value);
            setDelcount(dert.data.count);
            setModalShow(false);
            editclosemodal();
            navigate("/delivery");
        }
    } catch (ex) {
    }finally{
      setIsLoading(false);
    }
  };

  const submit = async () => {
    if (!cname) {
      await setSBtndis(false);
      toast.error("Select Firm Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!phone) {
      await setSBtndis(false);
      toast.error("Enter Mobile Number", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (phone.length < 10 && phone.length > 10) {
      await setSBtndis(false);
      toast.error("Invalid Phone", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!email) {
      await setSBtndis(false);
      toast.error("Enter Email", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!gstNo) {
      await setSBtndis(false);
      toast.error("Enter GST Number", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paytype) {
      await setSBtndis(false);
      toast.error("Select Payment type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!invoiceid) {
      await setSBtndis(false);
      toast.error("Enter Invoice ID", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paidAmount) {
      await setSBtndis(false);
      toast.error("Enter Received Amount", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!invoiceid) {
      await setSBtndis(false);
      toast.error("Enter Invoice ID", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paidAmount) {
      await setSBtndis(false);
      toast.error("Enter Received Amount", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      try {
      } catch (ex) {}
    }
  };
  const selectorder = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    const selclient = orders[selindex];
    console.log(selclient, "selorder");
    setOrderId(selclient.orderId);
    setorid(selclient._id);
    setCname(selclient.customerId.name);
  };
  const selectemps = async (e) => {
    console.log(e.target.selectedOptions[0].dataset.index, "value");
    const selindex = e.target.selectedOptions[0].dataset.index;
    const selclient = clients[selindex];
    setCname(selclient.name);
    setPhone(selclient.phoneNo);
    setCustomerId(selclient._id);
    setCid(selclient._id);
    setEmail(selclient.email);
    setGstNo(selclient.gstNo);
    console.log(selclient, "selclient");
    try {
      const det = await JobcardsService.getordersById(selclient._id);
      if (det.data.status === "success") {
        setOrders(det.data.value);
      }
    } catch (ex) {}
  };

  const closemodal = () => {
    setModalShow(false);
    setClient([]);
    setCname("");
    setCid("");
    setGstNo("");
    setEmail("");
    setPhone("");
    setFile("")
    console.log("closemodal");
  };
  const editclosemodal = () => {
    setEditModalShow(false);
    setClient([]);
    setCname("");
    setCid("");
    setGstNo("");
    setEmail("");
    setPhone("");
    setFile("")
    console.log("closemodal");
  };

  const getclients = async () => {
    try {
      const dert = await ClientsService.getclients();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setClients(dert.data.value);
      }
    } catch (ex) {}
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const changeHandler = async (event) => {
    console.log("calling");
    await setImloader(true);

    const formData = new FormData();

    formData.append("image", event.target.files[0]);
    console.log(event.target.files[0], "event.target.files");
    let res = await axios.post(
      "https://aretsdevapi.azurewebsites.net/api/common/uploadfile",
      formData,

      {
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data; ",
          Accept: "*/*",
        },
      }
    );
    console.log(res.data, "res.data");
    if (res.data.url) {
      await setImloader(false);

      await setFile(res.data.url);
    }
  };
  const delmg = async () => {
    var filename = file.substring(file.lastIndexOf("/") + 1);
    const obj = {
      url: filename,
    };
    const dete = await estimateService.delproimg(obj);
    setFile('');
    if (dete.data.status === true) {
      toast.success("File Deleted SuccessFully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  const deleditmg = async () => {
    var filename = file.substring(file.lastIndexOf("/") + 1);
    const obj = {
      url: filename,
    };
    const dete = await estimateService.delproimg(obj);
    setFile('');
    if (dete.data.status === true) {
      toast.success("File Deleted SuccessFully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setFile('');
    }
  };
  const clickedit = (k, item) => {
    console.log("first time loading ");
    console.log(k, "str");
    setedmill(k);
    setorid(k.orId._id);
    setNewCname(k.customerName);
    setPhoneNo(k.phone);
    setOrderId(k.orderId);
    setCustomerId(k._id);
    setCid(k._id);
    setNewEmail(k.email);
    setNewGstNo(k.gstNo);
    setNewlrNumber(k.lrNo);
    setNewaddress(k.address);
    setDate(Moment(k.deliverDate).format("YYYY-MM-DD"));
    setUStatus(k.status);
    setEditModalShow(true);
    setPendingAmount(k.orId.pendingAmount);
    setSumTotal(k.orId.sum_total)
  };
  const vieworder = (item) => {
    console.log("item==", item);
    setModalShow(true);
  };
  const changeStatus = (value) => {
    console.log("value==", value);
    setUStatus(value);
    setstatus(value);
    // getestimates(0,10)
  };
  

    const serachclick = async () => {
        
      const trimmedSearchText = searchtext.trim(); 
      if (trimmedSearchText === "") {
        toast.error("Enter Text", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
     
      }
          else{
     
        try {
          const obj = {
            skip: 0,
            limit: rowsPerPage,
            searchkeys: { customerName: trimmedSearchText },
        };
        const dert = await deliveryService.getdeliver(obj);
        console.log(dert, "dert");
        if (dert.data.status === 'success') {
            console.log(dert.data.value, 'values');
            setDeliver(dert.data.value);
            setDelcount(dert.data.count);
            setSearch(true);
        }
        } catch (ex) {}
        }
      };
      const resetclick = () => {
        setSearchText("");
          setSearch(false)
          resetorder(0,rowsPerPage);
      }
    
      
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          serachclick();
         
        }
      };

  const searchChange = (value) => {
    setSearchText(value);
    // getestimates(0,10)
  };
  console.log(date, "dat");
  //add cash receipt Integration close

  const handleStatus = async (e, item) => {
    console.log(item, "e.target.value");
    try {
      const obj = {
        // _id: edmill._id,
        _id: item._id,
        orderId: item.orderId,
        status: e.target.value,
      };
      const dort = await deliveryService.updateDeliver(obj);
      console.log(dort, 'dorttt');
      if (dort.status === 200) {
        toast.success("Delivery updated Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getdeliver(0, 10);
        
      }
    } catch (ex) {
      console.log(ex, "ex.response");
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
 
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={8} className="containerr">
            <div className="d-flex">
              <div className="col-lg-9 col-sm-9">
                <h1 className="fs-3 mx-5 mt-4">Delivery</h1>
                <Breadcrumb className="mx-5 fs-14">
                  <Breadcrumb.Item active>Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Delivery</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="col-lg-3 col-sm-2 mt-5">
                <Button
                  onClick={() => vieworder()} 
                  id="pillStyle"
                  className="btnnew"
                  variant="primary"
                >
                  New Delivery Receipt
                </Button>
              </div>
            </div>
            <Row className="d-flex ms-1 me-1">
              <Col
                lg={12}
                sm={12}
                md={6}
                xs={12}
                className="mb-5 mt-1 datacenter"
              >
                <Paper className="col-lg-12 col-sm-8 rounded-4">
                  <Box sx={{}}>
                    <Row>
                      <Col lg={4} sm={12} className="text-lg-start ">
                        <h6 className="datahead text-muted">Customers</h6>
                      </Col>
                      {/* categories starts */}
                      <Col lg={8} sm={12} className="px-4 pb-4">
                        <Row className="d-flex justify-content-end">
                          <Col lg={5} xs={12}>
                            <Form className="mt-3 d-flex gap-2 searchField">
                              <div className="col-lg-8 d-flex">
                                <input
                                  value={searchtext}
                                  onChange={(e) => searchChange(e.target.value)} onKeyDown={handleKeyDown}
                                  className="form-control nosubmit"
                                  style={{ fontSize: "14px" }}
                                  type="search"
                                  placeholder="Search Firm Name"
                                />
                              </div>
                              <div className="col-lg-4">
                                {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    id='cancel'
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  
                                  >
                                    Search
                                  </Button>
                                ) : (

                                    
                                  <Button 
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}
                                    


                                {/* <span className="searchcont" onClick={() => serachclick()} style={{cursor:'pointer'}}><i class="fa fa-search empsearch"></i></span> */}
                              </div>
                            </Form>
                          </Col>

                          {/* Export Excel */}
                          <Col lg={3} sm={2} className="pe-1">
                            <Button
                              onClick={handleDownloadExcel}
                              disabled={isDownloading}
                              id="shareIcon"
                              variant="outlined"
                            >
                              <img
                                className="menuIcon mx-1 mb-1"
                                src={shareIcon}
                              />
                              {isDownloading ? "Downloading" : "Export Excel"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                          <th>
                            <div className="table-headFilter ps-2">
                              <span>#</span>
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Firm Name</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          {/* <th> */}
                          {/* <div className='table-headFilter'>
                                                            <span>Order Name</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div> */}
                          {/* </th> */}
                          <th>
                            <div className="table-headFilter">
                              <span>Orders ID</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>LR Number</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Payment Status</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Address</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <span>Delivery Date && Time</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th> */}
                          <th>
                            <div className="table-headFilter">
                              <span>Action</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Status</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {delivery && delivery.length > 0
                          ? delivery.map((k, index) => {
                              return (
                                <tr>
                                  <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                  <td>{k.customerName}</td>
                                  {/* <td>{k.customerName}</td> */}
                                  <td>{k.orderId}</td>
                                  <td>{k.lrNo}</td>
                                  <td className={k.orId.pendingAmount === 0 ? "txt-green" : "text-danger"}>
                                    {k.orId.pendingAmount === 0
                                      ? "Payment Completed"
                                      : "Payment Pending"}
                                  </td>
                                  <td>{k.address}</td>
                                  <td className="accordionsize">
                                    {" "}
                                    <Form.Select
                                      id="accordion-body"
                                      value={k.status}
                                      onChange={(e) => handleStatus(e, k)}
                                      aria-label="Default select example"
                                    >
                                      <option value="Deliever-Completed">
                                        Deliver Completed
                                      </option>
                                      <option value="Deliever-Pending">
                                        Deliver Pending
                                      </option>
                                      <option value="Deliever-Cancelled">
                                        Deliver Canceled
                                      </option>
                                    </Form.Select>
                                  </td>
                                  <td>{k.status}</td>

                                                                 <td onClick={() => clickedit(k)} className="hand-cursor"><img className="menuIcon mx-1" src={eyeIcon} />view</td> 

                                                            </tr>
                                                        )
                                                    }) : null
                                                }
                                            </tbody>
                                        </Table>
                                    
                                        <Row className='d-flex'>
                                            <Col> <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={delcount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal
        show={modalShow}
        onHide={() => modalreset(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            New Delivery Receipt
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className=" rounded-4">
            <CardContent>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <select
                        onChange={(e) => selectemps(e)}
                        className="form-select text-muted me-2"
                        id="inputcolorr"
                        aria-label="Default select example"
                        placeholder="Customer Name*"
                      >
                        <option hidden value>
                          Select Customer Name*
                        </option>
                        {clients &&
                          clients.map((k, index) => {
                            return (
                              <option
                                key={index}
                                value={k.name}
                                data-index={index}
                              >
                                {k.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <select
                        onChange={(e) => selectorder(e)}
                        className="form-select text-muted me-2"
                        id="inputcolorr"
                        aria-label="Default select example"
                        placeholder="Select/Enter Order ID*"
                      >
                        <option hidden value>
                          Select/Enter Order ID*
                        </option>
                        {orders &&
                          orders.map((k, index) => {
                            return (
                              <option key={index} value={k} data-index={index}>
                                {k.orderId}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control required"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            id="inputcolorr"
                            maxLength={10}
                            placeholder="Mobile Number*"
                            aria-label="phone"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control text-muted required"
                        name="name"
                        id="inputcolorr"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control text-muted required"
                        name="name"
                        id="inputcolorr"
                        value={gstNo}
                        onChange={(e) => setGstNo(e.target.value)}
                        placeholder="GST No"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control text-muted required"
                        name="name"
                        id="inputcolorr"
                        value={lrNumber}
                        onChange={(e) => setlrNumber(e.target.value)}
                        placeholder="LR Number"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                        <h6 className=""><small>Delivery Details</small></h6>
                                    </div> */}

                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="date" value={date} onChange={(e) => setDate(e.target.value)} id='inputcolorr' className="form-control text-muted required" name="date " />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="time" value={time} onChange={(e) => setTime(e.target.value)} className="form-control text-muted required" id='inputcolorr' name="time" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control text-muted required"
                        name="name"
                        value={address}
                        onChange={(e) => setaddress(e.target.value)}
                        id="inputcolorr"
                        placeholder="Address*"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control text-muted required"
                        name="name"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        id="inputcolorr"
                        placeholder="Remarks"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>

                  {
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-2">
                        {!file ? (
                          <button
                            onClick={handleClick}
                            type="file"
                            class="btn btn-outline-primary w-100"
                          >
                            <input
                              type="file"
                              accept="application/pdf, image/jpeg, image/png"
                              ref={hiddenFileInput}
                              onChange={changeHandler}
                              style={{ display: "none" }}
                            />
                            <img
                              className="menuIcon mb-1 mx-1"
                              src={UploadImageIcon}
                            />
                            <img
                              className="menuIcon mb-1 mx-1"
                              src={uploadCloudImage}
                            />
                            Upload Print File
                          </button>
                        ) : (
                          <div>
                            {file.includes("pdf") ? (
                              <div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => delmg()}
                                >
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      top: -12,
                                      backgroundColor: "red",
                                      width: 20,
                                      height: 20,
                                      borderRadius: 100,
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      style={{
                                        color: "white",
                                        marginLeft: "4px",
                                      }}
                                      className="fa fa-times"
                                    ></i>
                                  </span>
                                </div>
                                <iframe
                                  id="attachment"
                                  src={file}
                                  width="100%"
                                  height="100%"
                                ></iframe>
                              </div>
                            ) : (
                              <div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => delmg()}
                                >
                                  <span
                                    style={{
                                      position: "absolute",
                                      left: 68,
                                      top: -12,
                                      backgroundColor: "red",
                                      width: 20,
                                      height: 20,
                                      borderRadius: 100,
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      style={{
                                        color: "white",
                                        marginLeft: "4px",
                                      }}
                                      className="fa fa-times"
                                    ></i>
                                  </span>
                                </div>
                                <img
                                  src={file}
                                  style={{ width: 80, height: 80 }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  }

                  {/* <select class="form-select text-muted mt-3" id='inputcolorr' aria-label="Default select example">
                                        <option value={status} onChange={(e) => setstatus(e.target.value)} selected hidden>Status</option>
                                        <option value="Deliever-Pending">Pending</option>
                                        <option value="Deliever-Completed">Delivered</option>

                                    </select> */}

                  <div className="row mt-3">
                    <div className="col-lg-6">
                      <Button
                        onClick={() => modalreset()}
                        id="pillStyle"
                        className="w-100"
                        variant="outline-primary"
                      >
                        Back
                      </Button>
                    </div>
                    <div className="col-lg-6">
                    {!isLoading ? (
                      <Button
                        onClick={() => create()}
                        id="pillStyle"
                        className="w-100"
                        variant="primary"
                      >
                        Create
                      </Button>
                       ) : (
                        <div className="loader mt-2" style={{marginLeft : '90px'}}></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
      <Modal
        show={editmodalShow}
        onHide={() => setEditModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            View Delivery Receipt
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className=" rounded-4">
            <CardContent>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control required"
                        name="phone"
                        value={newcname}
                        onChange={(e) => setNewCname(e.target.value)}
                        id="inputcolorr"
                        maxLength={50}
                        placeholder="Firm Name*"
                        aria-label="cname"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control required"
                        name="phone"
                        value={orderId}
                        onChange={(e) => setOrderId(e.target.value)}
                        id="inputcolorr"
                        maxLength={10}
                        placeholder="Order ID*"
                        aria-label="phone"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control required"
                            name="phone"
                            value={phoneno}
                            onChange={(e) => setPhoneNo(e.target.value)}
                            id="inputcolorr"
                            maxLength={10}
                            placeholder="Mobile Number"
                            aria-label="phone"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control text-muted required"
                        name="name"
                        id="inputcolorr"
                        value={newemail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        placeholder="Email"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control text-muted required"
                        name="name"
                        id="inputcolorr"
                        value={newgstNo}
                        onChange={(e) => setNewGstNo(e.target.value)}
                        placeholder="GST No"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control text-muted required"
                        name="name"
                        id="inputcolorr"
                        value={newlrNumber}
                        onChange={(e) => setNewlrNumber(e.target.value)}
                        placeholder="LR Number"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                        <h6 className=""><small>Delivery Details</small></h6>
                                    </div> */}

                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="date" value={ moment(date).format('DD MMM YYYY')} onChange={(e) => setDate(e.target.value)} id='inputcolorr' className="form-control text-muted required" name="date " />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="time" value={time} onChange={(e) => setTime(e.target.value)} className="form-control text-muted required" id='inputcolorr' name="time" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control text-muted required"
                        name="name"
                        value={newaddress}
                        onChange={(e) => setNewaddress(e.target.value)}
                        id="inputcolorr"
                        placeholder="Address*"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control text-muted required"
                        name="name"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        id="inputcolorr"
                        placeholder="Remarks"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>

                  {
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-2">
                        {!file ? (
                          <button
                            onClick={handleClick}
                            type="file"
                            class="btn btn-outline-primary w-100"
                          >
                            <input
                              type="file"
                              accept="application/pdf, image/jpeg, image/png"
                              ref={hiddenFileInput}
                              onChange={changeHandler}
                              style={{ display: "none" }}
                            />
                            <img
                              className="menuIcon mb-1 mx-1"
                              src={UploadImageIcon}
                            />
                            <img
                              className="menuIcon mb-1 mx-1"
                              src={uploadCloudImage}
                            />
                            Upload Print File
                          </button>
                        ) : (
                          <div>
                            {file.includes("pdf") ? (
                              <div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => deleditmg()}
                                >
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      top: -12,
                                      backgroundColor: "red",
                                      width: 20,
                                      height: 20,
                                      borderRadius: 100,
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      style={{
                                        color: "white",
                                        marginLeft: "4px",
                                      }}
                                      className="fa fa-times"
                                    ></i>
                                  </span>
                                </div>
                                <iframe
                                  id="attachment"
                                  src={file}
                                  width="100%"
                                  height="100%"
                                ></iframe>
                              </div>
                            ) : (
                              <div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => deleditmg()}
                                >
                                  <span
                                    style={{
                                      position: "absolute",
                                      left: 68,
                                      top: -12,
                                      backgroundColor: "red",
                                      width: 20,
                                      height: 20,
                                      borderRadius: 100,
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      style={{
                                        color: "white",
                                        marginLeft: "4px",
                                      }}
                                      className="fa fa-times"
                                    ></i>
                                  </span>
                                </div>
                                <img
                                  src={file}
                                  style={{ width: 80, height: 80 }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  }
                  <h4 className="fs-5 mb-3">Payment Summary</h4>
                  <div className="row mt-2">
                    <div className="col-lg-8 text-primary">Total</div>
                    <div className="col-lg-4 text-primary">

                      {delivery && delivery.length > 0 ?sumtotal : ''} 
                      {/* {getDelSum_total()} */}

                   
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-lg-8 text-danger">Pending Amount</div>
                    <div className="col-lg-4 text-danger">

                      {delivery && delivery.length > 0
                        ? pendingAmount
                        : ""}{" "}
                  

                    </div>
                  </div>
                </div>

                {/* <select class="form-select text-muted mt-3" id='inputcolorr' aria-label="Default select example" value={ustatus} onChange={(e) => changeStatus(e.target.value)}>

                                        <option selected hidden>Status</option>
                                        <option value="Deliver-Pending">Deliver Pending</option>
                                        <option value="Deliver-Completed">Deliver Completed</option>
                                        <option value="Deliver-Cancelled">Deliver Canceled</option>
                                         
                                    </select> */}


                                    <div className="row mt-3">
                                        <div className="col-lg-6">
                                        <Button onClick={() => editclosemodal()} id='pillStyle' className='w-100' variant="outline-primary">Back</Button>
                                        </div>
                                        <div className="col-lg-6">
                                        {!isLoading ? (
                                            <Button onClick={() => deliveyupdate()} id='pillStyle' className='w-100' variant="primary">Update</Button>
                                            ) : (
                                              <div className="loader mt-2" style={{marginLeft : '90px'}}></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            
                            

                        </CardContent>
                    </Card>
                </Modal.Body>
            </Modal>

        </>
    );
                                  
 };
 
                       

export default Delivery;
