import httpService from "./httpService";


export async function getpapergsmAll(obj) {
	const data = await httpService.post('/newMaster/papergsm/getAll',obj);
	return data;
}
export async function getfinishsize(obj) {
	const data = await httpService.post('/newMaster/finishSize/getAll',obj);
	return data;
}
export async function getmachines() {
	const data = await httpService.post('/masterData/machines/getAll');
	return data;
}
export async function getmillsizes(obj) {
	const data = await httpService.post('/newMaster/millisize/getAll',obj);
	return data;
}
export async function getordertypes(obj) {
	const data = await httpService.post('/newMaster/orderType/getAll',obj);
	return data;
}
export async function getlaminations() {
	const data = await httpService.post('/masterData/laminations/getAll');
	return data;
}
export async function extrajobs() {
	const data = await httpService.post('/masterData/extraJobs/getAll');
	return data;
}
export async function delproimg(obj) {
	const data = await httpService.post('/common/deleteFilepath', obj);
	return data;
}
export async function estimatecalculation(obj) {
	const data = await httpService.post('/estimate/estimateCalculation', obj);
	return data;
}
export async function addestimation(obj) {
	const data = await httpService.post('/estimate/addEstimation', obj);
	return data;
}
export async function updateestimation(obj) {
	const data = await httpService.post('/estimate/updateEstimation', obj);
	return data;
}
export async function confirmorder(obj) {
	const data = await httpService.post('/estimate/confirmOrder', obj);
	return data;
}
export async function printjobtypes(obj) {
	const data = await httpService.post('/masterData/printJobTypes/getAll', obj);
	return data;
}
export async function papertypes() {
	const data = await httpService.post('/masterData/paperTypes/getAll');
	return data;
}
export async function getOrder(id) {
	const data = await httpService.get(`/estimate/getOrder/${id}`);
	return data;
}
export async function getOrders() {
	const data = await httpService.post(`/estimate/orderGetAll`);
	return data;
}
export async function getOrdertracking(obj) {
	const data = await httpService.post('/track/trackGetOrderId', obj);
	return data;
}
export async function updatestatus(obj) {
	const data = await httpService.post('/common/updateStatus', obj);
	return data;
}
export async function getTrackHistory() {
	const data = await httpService.post('/masterData/printJobTypes/getAll');
	return data;
}
export async function getpunchingtypes(obj) {
	const data = await httpService.post('/newMaster/punching/getAll',obj);
	return data;
}
export async function getpastingtypes(obj) {
	const data = await httpService.post('/newMaster/pasting/getAll',obj);
	return data;
}
export async function getcuttingsize(obj) {
	const data = await httpService.post('/newMaster/cuttingSize/getAll',obj);
	return data;
}
export default {
	getpapergsmAll,
	getfinishsize,
	getmachines,
	getmillsizes,
	getlaminations,
	extrajobs,
	delproimg,
	estimatecalculation,
	printjobtypes,
	addestimation,
	updateestimation,
	confirmorder,
	papertypes,
	getOrdertracking,
	updatestatus,
	getOrders,
	getOrder,
	getpunchingtypes,
	getpastingtypes,
	getordertypes,
	getcuttingsize
}