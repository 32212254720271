import React, { useEffect, useState, useRef } from 'react';

import '../../Styles/Orders.css'
import '../../fonts/Squ721Rm.woff';
import Header from '../header/Header';
import Container from 'react-bootstrap/Container';
import Sidebar from '../Sidenav/Sidebar';
import aretsLogo from '../../Images/aretsLogo.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Moment from 'moment';
// import Paper from '@mui/material/Paper';

// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import QrCode from '../../Images/QrCode.png'
import Button from 'react-bootstrap/Button';
import { NavLink, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap';
import estimateService from '../../services/estimateService';
import authService, { getestimates } from '../../services/authService';
import ClientsService from '../../services/ClientsService';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { toast } from "react-toastify";
import { useNavigate } from "react-router";



function PrintEstimate(props) {
    const location = useLocation()
    const rate = location.state?.item?.sum_total/location.state.item.quantity
    let navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [order, setOrder] = useState('')
    console.log(location,'print-estimate item');
    const [client, setClient] = useState(location.state?.item ? location.state.item.customerId : '');
    useEffect(() => {
        getclients()
    }, [props.userdata])
console.log(props.userdata, 'props');
    const getclients = async () => {

        try {

            const dert = await ClientsService.getclients();
            if (dert.data.status === 'success') {
                console.log(dert.data.value, 'values');
                setClients(dert.data.value);
            }
        } catch (ex) {

        }
    }


    const scaleFactor = 210 / 100; // Assuming elementWidth is the width of the "capture" element
    const scaledHeight = 75 * scaleFactor;
    const payref = useRef();
    const [tab, setTab] = useState("Order Confirmation");
    const [payview, setpayview] = useState(true);
    const savepdf = () => {
      html2canvas(document.querySelector("#capture"), { dpi: 400 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(imgData, "PNG", 0, 0, 210, scaledHeight); 
              pdf.save("Estimate Confirmation.pdf");
      });
    };

  const [orders, setOrders] = useState([])
    useEffect(() => {
        getorders(0,10);
    }, [])

    const getorders = async (skps,rpp) => {
        try {
            const obj = {
                skip: skps,
                limit: rpp,
                // "searchkeys": '',           
            }
            const dert = await authService.getorders(obj);
            if (dert.data.status === 'success') {
                console.log(dert.data.value, 'values');
                setOrders(dert.data.value);
                // setOrderCount(dert.data.count)
            }
        } catch (ex) {

        }

    }
    const confirmorder = async () => {
        try {
          const obj = {
            confirmedBy: props.userdata.user._id,
            estmateId: location.state.item._id,
          };
          console.log(location.state.item, 'location.state.item');
          console.log(obj, "obj");
          const drty = await estimateService.confirmorder(obj);
    
          if (drty.status === 200) {
            navigate('/orders')
            console.log(drty, "drtyyyy");
            getestimates(0, 10);
            setOrders(drty.value);
            toast.success("Order Confirmed Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        } catch (ex) {}
      };
    // console.log('order============', order)
console.log(location.state.item, 'location.state.item');
console.log(clients, 'clients');
    // console.log(order, 'order');
    const jobDetails = location.state?.item?.jobDetails;
const detailsArray = jobDetails ? jobDetails.split(',') : [];

const state = detailsArray[1] ? detailsArray[1] + ",".trim() : '';
const gsm = detailsArray[2] ? detailsArray[2].trim() + ' GSM' : '';
const side = detailsArray[3] ? detailsArray[3].trim() : '';
const color = detailsArray[4] ? detailsArray[4].trim() : '';
const fifthValue = detailsArray[5] ? detailsArray[5].trim() : '';
const sixthValue = detailsArray[6] ? detailsArray[6].trim() : '';
const seventhValue = detailsArray[7] ? detailsArray[7].trim() : '';
const eighthValue = detailsArray[8] ? detailsArray[8].trim() : '';
let output = state;

if (gsm) {
  output += `, ${gsm}`;
}
if (side) {
  output += `, ${side}`;
}
if (color) {
  output += `, ${color}`;
}
if (fifthValue) {
  output += `, ${fifthValue}`;
}
if (sixthValue) {
  output += `, ${sixthValue}`;
}
if (seventhValue) {
  output += ` ${seventhValue}`;
}
if (eighthValue) {
  output += ` ${eighthValue}`;
}
    return (
        <>
            <Header />
            <Container fluid className='containerr'>
                <Row>
                    {/* <Col lg={2} id="sidebar-wrapper" className='d-none d-md-block'>
            <Sidebar />
          </Col> */}
                    <Col lg={12} className="p-3">
                        <div>
                            <Breadcrumb className='mx-5'>
                                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/estimates">Estimates</Breadcrumb.Item>
                                <Breadcrumb.Item active>Estimate Form</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Card sx={{ minWidth: 275 }} className="mt-3 rounded-4">
                            <CardContent >
                                <section >
                                    <div id="capture" ref={payref}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12 col-md-12">
                                                <div className="d-flex">
                                                <div className="col-lg-8 mb-2 mt-5">
                              <img
                                src={aretsLogo}
                                alt="branding"
                                className="loginPage-brandImg mb-1"
                                style={{width: '70px', height: '60px', marginLeft : '85px',}}
                              />
                              <h3  style={{  fontSize: '32px', fontWeight: "400px" }}>
                               <span style={{color : '#1896cc'}}> AReTS GRAPHICS</span>
                                <h6 className='ms-lg-4 ' style={{ fontSize: "16px", fontWeight: '400' }}>
                                  EVERYTHING IN PRINTING
                                </h6>
                                </h3>
                            </div>
                                                    <div className="col-lg-4 mt-5">
                                                        <h6 className='fw-bolder m-0'>Regd. Office :</h6>
                                                        <h6 className='fw-normal m-0'>15-4-64, Gowliguda Chaman, Hyderabad 500012</h6>
                                                        <h6 className='fw-normal m-0'><span className='fw-bold'>Works :</span> 7-3-50, Airport road, Gagan Pahad,<br></br> Rajendra Nagar, GHMC</h6>
                                                        <h6 className='fw-normal m-0'>Phone: 9908085293 / 80192 09990 / 9393 00 9992</h6>
                                                        <h6 className='fw-normal m-0'>GSTIN: 36AEYPM2529L1ZR</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='border-dark border-bottom border-top pt-2'>
                                    <h4 className='text-center mt-1'>ESTIMATE FORM</h4>
                                    </div>
                                    <div className='col-lg-12'>
                                    <div className='mt-3'>
                                        <span className="ms-5">To,</span><br />
                                        </div>
                                                                <div className="d-flex ">
                                                                    <div className="col-lg-9 fw-bolder ms-5">{location.state?.item?.customerName} ({location.state?.item?.address})</div>
                                                                    <div>Est. No: {location.state?.item?.estimateId}&nbsp;</div>
                                                                </div>
                                                                <div className="d-flex">
                                                                <div className="ms-5 col-lg-9"> Phone: {location.state?.item?.mobile}</div><br />
                                                                    <div>Date: &nbsp; {Moment(location.state.item?.createDate).format('DD-MM-YYYY')}</div>
                                                                </div>
                                                                <span className="ms-5">GST : {location.state?.item?.gstNo}</span><br />
                                                                <br />
                                                                <div className="d-flex">
                                                                <div className="col-lg-9 ms-5">Job Name : {location.state?.item?.name}</div>
                                                                    <div>Booked: &nbsp; {location.state?.item?.createdBy.username}</div>
                                                                    
                                                                </div>
                                                                <div className="col-lg-9 ms-5">Job Type &nbsp;&nbsp;: {location.state?.item?.orderType}</div>
                                                                <br />
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12 col-md-12 mb-5">
                                                <table>
                                                    <tbody style={{zIndex:1}}>
                                                        {/* <tr className="col-lg-12">
                                                            <td colspan="7">
                                                                
                                                            </td>
                                                        </tr> */}
                                                        <tr className='col-lg-12 border border-dark'>
                                                            <td className='col-lg-1 fw-bolder'>S.No.</td>
                                                            <td className='col-lg-9 fw-bolder border-end border-start border-dark'>Description</td>
                                                            {/* <td className='col-lg-1 '>HSN Code</td>
                                                            <td className='col-lg-1 border-end border-start border-dark'>Gst %</td> */}
                                                            <td className='col-lg-1 fw-bolder'>Quantity</td>
                                                            <td className='col-lg-1 fw-bolder border-end border-start border-dark'>Rate</td>
                                                            <td className="p-2 fw-bolder">Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="align-text-top" rowspan="4">1.</td>
                                                            <td className="align-text-top border-end border-start border-dark" rowspan="4">
                                                                <div style={{fontWeight : 'bold'}}>
                                                                    {location.state?.item?.jobDetails.split(',')[0]}
                                                                </div>
                                                                <div >
                                                                    {output}
                                                                </div>
                                                            </td>
                                                            {/* <td rowspan="4" >{location.state?.item?.hsnCode}</td>
                                                            <td rowspan="4" className='border-end border-start border-dark'></td> */}
                                                            <td className="align-text-top" rowspan="4">{location.state?.item?.quantity}</td>
                                                            <td className="p-2 border-end border-start border-dark">{rate.toFixed(2)}<br /><br /><br /><br /></td>
                                                            <td className="p-2">{location.state?.item?.sum_total}<br /><br /><br /><br /></td>
                                                        </tr>
                                                        <tr className=' border-dark'>
                                                            <td className="p-2 border-start  border-end border-dark">Total</td>
                                                            <td className="p-2 border-dark border-bottom border-top">{location.state?.item?.sum_total}</td>
                                                        </tr>
                                                       
                                                        
                                                    </tbody>
                                                </table>
                                                <p>*18% GST Extra</p>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 d-flex'>
                                        <div className='col-lg-2'></div>
                                        {location.state.item && location.state.item.status === "Estimate-Created" ? (
                                            <div className="col-lg-3">
                                                <Button
                                                onClick={() => confirmorder()}
                                                id="pillStyle"
                                                className="w-100"
                                                variant="primary"
                                                >
                                                Confirm Order
                                                </Button>
                                            </div>
                                            ) : null}

                                        <div className="col-lg-2"></div>
                                    <div className="col-lg-3">
                                            <Button  onClick={() => savepdf()} id='pillStyle' className='w-100' variant="primary">Print Estimate Form</Button>
                                        </div>
                                        <div className='col-lg-2'></div>    
                                    </div>
                                </section>
                            </CardContent>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </>
    )
}
const mapStateToProps = state => {
    console.log(state, 'state');
    return {
      userdata: state.userdetails
    };
  };
  
  export default connect(mapStateToProps)(PrintEstimate);
